import React, {createRef, memo, useEffect} from "react";
import { Fade } from "react-reveal";

import "./timeLine.sass";

export const TimeLine = memo(() => {
  const distance = '250px';
  const wrapper = createRef();

  const onScrollJourney = () => {
    const rect = wrapper?.current?.getBoundingClientRect();
    const scrollContainerPageY = rect?.top;
    //indicate when block is placed in the middle of screen when equal 0
    //10px - distance difference in num and container alignment
    const scrollIndicator = scrollContainerPageY - window.innerHeight / 2 + 10;

    if (scrollIndicator < 0) {
      // starting point to draw line
      let scrollPercentage = Math.abs(scrollIndicator / wrapper?.current?.scrollHeight);
      let pagePercentage = 0.87;
      if (window.innerWidth >= 1600 || window.innerWidth < 992) {
        pagePercentage = 0.89;
        if (window.innerHeight > 800) {
          pagePercentage = 0.84
        }
      }

      if (scrollPercentage < pagePercentage) {
        const height = wrapper?.current?.scrollHeight * scrollPercentage;
        const style = wrapper?.current?.style;
        style.setProperty('--height', `${height}px`);
      }
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', onScrollJourney);
    return () => document.removeEventListener('scroll', onScrollJourney);
  })

  return (
      <section className="timeline">
        <div className="wrapper" ref={wrapper}>
          <div className="container left">
            <Fade left={true} distance={distance}>
              <div className="content">
                <h3 className="number">01</h3>
                <h2>We Connect</h2>
                <p>
                  Our connection with you is the key to your connection with your audience and telling your story authentically.
                  Getting to know you begins with a one-on-one consultation designed to encourage a partnership approach from the beginning.
                </p>
              </div>
            </Fade>
          </div>
          <div className="container right">
            <Fade right={true} distance={distance}>
              <div className="content">
                <h3 className="number">02</h3>
                <h2>We Understand</h2>
                <p>
                  That begins with effective listening and getting to the core of your challenges and opportunities with informed questioning.
                  It’s all essential to generating ideas and developing strategies that shape success within realistic, achievable timeframes.
                </p>
              </div>
            </Fade>
          </div>
          <div className="container left">
            <Fade left={true} distance={distance}>
              <div className="content">
                <h3 className="number">03</h3>
                <h2>We Know</h2>
                <p>
                  With extensive experience of research and discovery,
                  we can investigate and articulate the information and trends most pertinent to you and your ambitions.
                </p>
              </div>
            </Fade>
          </div>
          <div className="container right">
            <Fade right={true} distance={distance}>
              <div className="content">
                <h3 className="number">04</h3>
                <h2>We Strategise</h2>
                <p>
                  Knowledge is nothing if not applied in the most efficient, effective way.
                  Our people are expert in defining results focused strategies that are tailored to maximise brand stand out in any market.
                </p>
              </div>
            </Fade>
          </div>
          <div className="container left">
            <Fade left={true} distance={distance}>
              <div className="content">
                <h3 className="number">05</h3>
                <h2>We Execute</h2>
                <p>
                  We put all of our understanding and knowledge, experience and inspiration,
                  into developing a brand that combines and amplifies your strengths and assets,
                  and that you can bring to life, honestly and authentically.
                </p>
              </div>
            </Fade>
          </div>
          <div className="container right">
            <Fade right={true} distance={distance}>
              <div className="content">
                <h3 className="number">06</h3>
                <h2>We Deliver</h2>
                <p>
                  We do what we say we’ll do, on time, every time, ensuring you can rely on our team to deliver
                  for your business and your brand – creatively, intelligently and by way of exceptional service.
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </section>
  );
})

TimeLine.displayName = 'TimeLine';
