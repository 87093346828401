import React, { useEffect, memo, useCallback, useState } from "react";
import { Fade } from "react-reveal";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Circle } from "assets/images/new/o small.svg";
import { ReactComponent as Cross } from "assets/images/new/x small.svg";

import "./anchors.sass";

const NavList = memo(({ element, index, pathname, onClick, activeAnchor }) => {
  const [isHover, setIsHover] = useState(false);
  const [active, setActive] = useState('active')

  return (
      <Link to={`${pathname}#${element.id}`}
            onClick={() => onClick(element, index)}
            className={isHover ? 'hovered' : ''}
            onMouseLeave={() => {
                setIsHover(false)
            }}
      >
        <div
            className={`label ${activeAnchor === index && active}`}
        >
          <span>{element.label}</span>
          {activeAnchor === index ? <Cross onClick={() => { }} style={{ color: "red" }} /> :
              <Circle
                  onMouseEnter={() => {
                    setIsHover(true)
                  }}
              />}
        </div>
      </Link>
  )
});

export const Anchors = memo(({ anchors, isBlack, onClick, setHash }) => {
    const [activeAnchor, setAnchor] = useState(0);
    const { pathname } = useLocation();
    // used to prevent re-rendering of the component when user clicks on the link
    const [render, setRender] = useState(false);

    const scrollHandler = useCallback(
        () => {
            for (let i = 0; i < anchors.length; i++) {
                const pageAnchor = document.querySelector(`#${anchors[i].id}`);

                const pageAnchorTop = pageAnchor?.getBoundingClientRect().top;
                const pageAnchorHeight = pageAnchor?.clientHeight;

                if (pageAnchorTop < 100 && pageAnchorTop > pageAnchorHeight * -1) {
                    if (!render) {
                        setAnchor(i);
                        setHash(anchors[i])
                    }
                }
            }
        },
        [anchors, setHash, render],
    )

    const handleClick = useCallback((element, index) => {
        setRender(true)
        setAnchor(index);
        onClick && onClick(element)
        setTimeout(() => {
            setRender(false)
        }, 1000);

    }, [onClick])

    useEffect(() => {
        document.addEventListener("scroll", scrollHandler);
        return () => document.removeEventListener("scroll", scrollHandler);
    });

    return (
        <section className={`anchors ${isBlack ? "black" : ""}`}>
            <Fade right={true} delay={500}>
                {
                    anchors.map((element, index) => <NavList key={index} index={index} element={element} pathname={pathname} activeAnchor={activeAnchor} onClick={handleClick} />)
                }
            </Fade>
        </section>
    );
});
