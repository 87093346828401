import React from 'react'

const Field = props => {
	const {
		id,
		labelText,
		type,
		name,
		value,
		error,
		onChange,
		className,
		field_color
	} = props
	return (
		<div className="form-group">
			{/*<label htmlFor={id}>{labelText}</label>*/}
			<input
				type={type}
				id={id}
				className={`form-control ${className || ''}`}
				name={name}
				value={value}
				onChange={onChange}
				placeholder={labelText}
				style={{backgroundColor: field_color ? field_color : ''}}
			/>
			{error ? <div className="invalid-feedback">{error}</div> : null}
		</div>
	)
}

export default Field