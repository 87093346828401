import React, {memo} from 'react';

import './RelatedArticles.scss';

export const RelatedArticles = memo(({ title, articles }) => {
    if (articles.length !== 0) {
        return (
            <section className="related-articles">
                <div className="content">
                    <h2>{title}</h2>
                    <div className="articles-container">
                        {articles?.map(article => article)}
                    </div>
                </div>
            </section>
        )
    }
    return null;
})

RelatedArticles.displayName = 'RelatedArticles';
