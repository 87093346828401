import React, {memo} from 'react';
import { ItemsList } from '../ItemsList';

import './serviceSection.scss';

import { BRAND_CONSULTATION_ROUTE, WEB_CONSULTATION_ROUTE, DIGITAL_CONSULTATION_ROUTE, GENERAL_CONSULTATION_ROUTE } from '../../constants/routes';
import Button from 'components/Button';
import { useHistory } from "react-router-dom";
import { removeInlineStyles } from "utils/helpers";

export const ServiceSection = memo(({
    title,
    first_description,
    first_image,
    first_image_alt,
    first_image_shadow,
    second_description,
    second_image,
    second_image_alt,
    second_image_shadow,
    third_description,
    third_image,
    third_image_alt,
    third_image_shadow,
    firstButtonLabel,
    secondButtonLabel,
    benefits_list,
    linkLabel,
    linkHref,
    color,
    action_url,
    consultation_type}) => {
    const history = useHistory();

    const createMarkup = (description) => {
        return { __html: removeInlineStyles(description) };
    };

    const consultationPathname = (type) => {
        switch (type) {
            case 'Brand':
                return BRAND_CONSULTATION_ROUTE
            case 'Website':
                return WEB_CONSULTATION_ROUTE
            case 'Marketing':
                return DIGITAL_CONSULTATION_ROUTE
            default:
                break;
        }
    }

    return (
        <section className="service-section">
            <div className="content">
                <div className="content_first _content">
                    <div className="first_text text">
                        <h2 className='section-title'>{title}</h2>
                        <div className="description main" dangerouslySetInnerHTML={createMarkup(first_description)}></div>
                        <Button
                            styleType='consultation__link ordinaryBtn black'
                            handleClick={() => action_url ? history.push(action_url) : history.push(GENERAL_CONSULTATION_ROUTE)}
                            label={firstButtonLabel}
                        />
                    </div>
                    <div className="first_image image">
                        <img src={first_image} alt={first_image_alt} loading={'lazy'} style={{ filter: first_image_shadow ? '' : 'unset'}}/>
                    </div>
                </div>
                <div className="content_second _content">
                    <div className="second_text text">
                        <div className="description" dangerouslySetInnerHTML={createMarkup(second_description)}></div>
                    </div>
                    <div className="second_image image">
                        <img src={second_image} alt={second_image_alt} loading={'lazy'} style={{ filter: second_image_shadow ? '' : 'unset' }}/>
                    </div>
                </div>
                <div className="content_third _content">
                    <div className="third_text text">
                        <div className="description" dangerouslySetInnerHTML={createMarkup(third_description)}></div>
                        <Button
                            styleType='consultation__link ordinaryBtn black'
                            link={{ pathname: consultationPathname(consultation_type) }}
                            label={secondButtonLabel}
                        />
                    </div>
                    <div className="third_image image">
                        <img src={third_image} alt={third_image_alt} loading={'lazy'} style={{ filter: third_image_shadow ? '' : 'unset' }}/>
                    </div>
                </div>
            </div>
            <ItemsList
                items={benefits_list}
                linkHref={linkHref}
                linkLabel={linkLabel}
                title='Benefits'
                color={color}
                className={`service-footer ${consultation_type}`}
            />
        </section>
    )
})

ServiceSection.displayName = 'ServiceSection';
