import React from "react";
import { Fade, Zoom } from "react-reveal";

import "./heroX.sass";

import xGreen from "assets/images/new/Client Commitment/X-green-no-shadow.svg";
import circle from "assets/images/new/Client Commitment/circle-small-outline-grey.svg";
import hexagon from "assets/images/new/Client Commitment/hexagon-small-outline-grey.svg";
import square from "assets/images/new/Client Commitment/square-small-outline-grey.svg";
import triangle from "assets/images/new/Client Commitment/Triangle-small-outline-grey.svg";
import xSmall from "assets/images/new/Client Commitment/x-small-outline-grey.svg";

import {ReviewsStars} from "components/ReviewsStars/ReviewsStars";

export const HeroX = ({ title, subtitle, left = true, bottom = false, figures, stars, state = 'none', visible = true, review = null }) => {
    const distance = "100px";

    const reviewCount = review?.map(e => e.reviews_count);

    return (
        <section className="hero-x">
            <div className='content'>
                {visible &&
                    <div className='info'>
                        <Fade left={left} distance={distance} duration={500} bottom={bottom}>
                            <h1 className='title'>{title}</h1>
                            {subtitle}
                        </Fade>
                        {stars &&
                            <ReviewsStars
                                reviewCount={reviewCount[0]}
                            />
                        }
                    </div>}
                {figures &&
                    <div className='figures clients-figures'>
                        <img src={xGreen} alt='x-green' className="x-green" />
                        {(state.includes("top") || state === "full") && (
                            <React.Fragment>
                                <Zoom delay={150}>
                                    <img src={triangle} alt='riangle-left' className='small-figures triangle triangle-left' />
                                </Zoom>
                                <Zoom delay={300}>
                                    <img src={triangle} alt='riangle-right' className='small-figures triangle triangle-right' />
                                </Zoom>
                                <Zoom delay={450}>
                                    <img src={xSmall} alt='x-right' className='small-figures x x-right' />
                                </Zoom>
                                <Zoom delay={600}>
                                    <img src={hexagon} alt='hexagon-center' className='small-figures hexagon hexagon-center' />
                                </Zoom>
                                <Zoom delay={750}>
                                    <img src={circle} alt='circle-bottom' className='small-figures circle circle-bottom' />
                                </Zoom>
                                <Zoom delay={900}>
                                    <img src={xSmall} alt='x-bottom' className='small-figures x x-bottom' />
                                </Zoom>
                            </React.Fragment>
                        )}
                        {(state === "full") && (
                            <React.Fragment>
                                <Zoom delay={150}>
                                    <img src={circle} alt='circle-center' className='small-figures circle circle-center' />
                                </Zoom>
                                <Zoom delay={300}>
                                    <img src={hexagon} alt='hexagon-top' className='small-figures hexagon hexagon-top' />
                                </Zoom>
                                <Zoom delay={450}>
                                    <img src={triangle} alt='triangle-center' className='small-figures triangle triangle-center' />
                                </Zoom>
                                <Zoom delay={600}>
                                    <img src={square} alt='square-bottom' className='small-figures square square-bottom' />
                                </Zoom>
                                <Zoom delay={750}>
                                    <img src={xSmall} alt='x-left' className='small-figures x x-left' />
                                </Zoom>
                                <Zoom delay={900}>
                                    <img src={xSmall} alt='x-center' className='small-figures x x-center' />
                                </Zoom>
                            </React.Fragment>
                        )}
                    </div>
                }
            </div>
        </section>
    )
}