import React, { useEffect } from "react";

import "./services.sass";
import brand from "assets/images/new/Define Your Project/brand-marketing-symbols.svg";
import online from "assets/images/new/Define Your Project/online-experiences-symbols.svg";
import digital from "assets/images/new/Define Your Project/digital-marketing-symbols.svg";
import budget from "assets/images/new/Define Your Project/bugdet.svg";
// import arrow from "assets/images/new/Define Your Project/up-arrow.svg";
import { ReactComponent as Tick } from "assets/images/new/Define Your Project/tick.svg";
// import { ReactComponent as Price } from "assets/images/new/Define Your Project/price-hexagon.svg";


export const Services = ({ data, setData }) => {

  // const calculateGroup = array => {
  //   return array.blocks.reduce((acc, obj) => {
  //     return obj.isActive ? acc + Number(obj.price) : acc;
  //   }, 0);
  // };
  //
  // const scrollToPrev = (number) =>
  //   window.scrollTo({ top: window.innerHeight * number, behavior: "smooth" });
  //
  // const scrollToNext = (number) =>
  //   window.scrollTo({ top: window.innerHeight * number, behavior: "smooth" });

  const scrollImitation = () => {
    let lastX = 0;
    let select = document.querySelectorAll(".select");

    let ok = false;

    select.forEach(el => el.onmousedown = function (e) {
      ok = true;
    })

    select.forEach(el => el.onmouseup = (e) => {
      ok = false
    })

    select.forEach(el => el.onmousemove = function (e) {
      if (ok) {
        el.scrollLeft -= e.clientX - lastX;
        lastX = e.clientX;
      }
    })
  }

  useEffect(() => {
    scrollImitation();
  });

  const Images = [
    {
      image: brand
    },
    {
      image: online
    },
    {
      image: digital
    },
    {
      image: budget
    }
  ]

  return (
    <section className="available-services">
      {data.map((services, i) => (
        <div className="wrapper" key={i}>
          <div className="group">
            <div className="number">
              <div>
                <span>0{i + 1}</span>
                <img
                  src={Images?.[i].image ?? digital}
                  alt="symbols"
                />
              </div>
              {/*<div className="controls" onClick={() => scrollToPrev(i)}>*/}
              {/*  Previous <img src={arrow} alt="arrow" />*/}
              {/*</div>*/}
            </div>
            <h2 className="type">{services.header}</h2>

            <div className="select">
              {services?.blocks?.map((service, ind) => (
                <div
                  key={ind}
                  className={`option ${service.isActive && "active"}`}
                  onClick={() => {
                    const newData = [...data];
                    newData[i].blocks[ind].isActive = !newData[i].blocks[ind].isActive;
                    setData(newData);
                  }}
                >
                  <div className="tick">
                    <Tick />
                  </div>
                  <div className="text">
                    <h2>{service.title}</h2>
                  </div>
                </div>
              ))}
              {/*<div className="price">*/}
              {/*  <div className="text">*/}
              {/*    <h2 className="title">*/}
              {/*      Investment <br /> from only*/}
              {/*  </h2>*/}
              {/*    <div className="amount">*/}
              {/*      <h2><span>£{calculateGroup(services)}</span></h2>*/}
              {/*      <span>per month</span>*/}
              {/*    </div>*/}
              {/*    <div className="tip">*/}
              {/*      *Subject to consultation.<br /> Minimum term applies.*/}
              {/*  </div>*/}
              {/*  </div>*/}
              {/*  <Price />*/}
              {/*</div>*/}
            </div>
          </div>
          {/*<div className="controls-wrapper">*/}
          {/*  <div className="controls-bottom" onClick={() => scrollToNext(i + 2)}>*/}
          {/*    Next <img src={arrow} alt="arrow" />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      ))}
    </section>
  );
};
