import React, {useRef, useEffect, useState, memo} from "react";

import "./ourProcess.sass";
import { TimeLine } from "components";
import { HeroHexagon } from "./heroHexagon";

export const OurProcess = memo(() => {
  const processContainer = useRef();
  const [state, setState] = useState("top");

  const scrollHandler = () => {

    if (window.innerWidth >= 350) {
      const top = processContainer?.current?.getBoundingClientRect().top;
      const height = document.querySelector('.hero-hexagon').offsetHeight;
      const figures = document.querySelector('.process-figures');

      let _bottom = 450;
      let _top = 150
      if (window.innerWidth < 1530 && window.innerWidth >= 992) {
        _bottom = 850
        _top = -350
      }
      if (window.innerWidth < 992) {
        _top = 50
        _bottom = 150
      }

      if (top < _top && top > (height * -2 - _bottom)) {
        if (!figures.classList.contains('in')) {
          figures.classList.add('in')
        }

        if (top > height * -1 + 150) {
          if (state !== "top") {
            setState("top")
          }
        } else if (top <= height * -1 + 150 && top > height * -2 + 150) {
          if (state !== "top center") {
            setState("top center")
          }
        } else {
          if (state !== "full") {
            setState("full");
          }
        }
      } else {
        if (figures.classList.contains('in')) {
          figures.classList.remove('in')
          setState("none");
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);
    return () => document.removeEventListener("scroll", scrollHandler);
  });

  return (
      <section className="our-process" id="our-process" ref={processContainer}>
        <HeroHexagon
            state={state}
            title={
              <h1>The shape<br></br>
                of things to come
              </h1>
            }
            subtitle={
              <h2>
                You are here because you need a partner who can get <b>your
                brand and business</b> to where it should rightfully be.
                <p>We are here for that singular reason too.</p>
              </h2>
            }
            figures={true}
            visible={window.innerWidth < 1530 || state === 'top'}
        />
        <HeroHexagon
            state="top center"
            title={null}
            subtitle={
              <h2>
                Our purpose is to build brands that resonate and engage.
                That are effective in the most efficient way possible.
                That transforms any branding exercise from a cost to <b>an
                investment that maximises potential for returns.</b>
              </h2>
            }
            figures={window.innerWidth < 1530}
            visible={state === 'top center' || state === 'top'}
        />
        <HeroHexagon
            state="full"
            title={
              <h1>This is how we do it</h1>
            }
            subtitle={
              <h2>
                Our tailored process is fine tuned to focus on delivery. We spend
                time <b>developing relationships, balancing expectations</b> and
                applying our<br></br>
                <b>core values</b> to build solid foundations that
                deliver long term results.
              </h2>
            }
            figures={window.innerWidth < 1530}
            visible={state === 'full' || state === 'top center'}
        />
        <TimeLine />
      </section>
  );
})

