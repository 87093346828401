import React, {memo} from "react";
import { Fade } from "react-reveal";

import "./ourConsultant.sass";

import { ReactComponent as Mouse } from "assets/images/new/mouse.svg";

export const OurConsultant = memo(({ id, title, subtitle, text, mouse, img }) => {
  return (
      <section className='our-consultant' id={id}>
        <div className='content'>
          <Fade left={true}>
            <div className='text'>
              {title}
              {subtitle}
              {text}

              {mouse &&
                  <div className='mouse'>
                    <Mouse
                        onClick={() =>
                            window.scrollTo({
                              top: window.innerHeight + 200,
                              behavior: "smooth",
                            })
                        }
                    />
                    <p>Meet our team</p>
                  </div>}
            </div>
          </Fade>
          <Fade right={true} distance='400px'>
            <div className='photo '>
              <img className='drop-shadow' src={img} alt={id} loading={'lazy'} />
            </div>
          </Fade>
        </div>
      </section>
  );
});

OurConsultant.displayName = 'OurConsultant';
