import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { URL_ORIGIN } from "utils/helpers";
import banner from 'assets/images/banner.jpg';

const MetaData = memo(({ title, description, image, canonicalUrl }) => {
    const defaultTitle = "The Heighton Agency";
    const defaultDescription = 'Contact our brand, design, web and digital team in Horam, Heathfield, East Sussex on +44 (0) 1435 692 023';

    return (
        <Helmet>
            <meta property="og:type" content="website" />
            <title>{title ? title : 'The Heighton Agency'}</title>
            <meta property="og:site_name" content={title ? title : defaultTitle} />
            <meta property='og:title' content={title ? title : defaultTitle}/>
            <meta itemProp="name" content={title ? title : defaultTitle} />
            <meta name="twitter:title" content={title ? title : defaultTitle} />

            <meta name="description" content={description ? description : defaultDescription} />
            <meta property="og:description" content={description ? description : defaultDescription} />
            <meta property="twitter:description" content={description ? description : defaultDescription} />

            <link rel="image_src" href={image ? image : banner}/>}
            <meta property="og:image" itemProp="image" content={image ? image : banner} /> }
            <meta property="twitter:image" itemProp="image" content={image ? image : banner} />}
            <meta itemProp="image" content={image ? image : banner} />}
            <meta itemProp="thumbnailUrl" content={image ? image : banner} />}
            <meta name="twitter:card" content={'summary_large_image'} />}
            <meta name="twitter:image" content={image ? image : banner} />}

            <meta itemProp="url" content={canonicalUrl ? canonicalUrl : URL_ORIGIN} />
            <meta property='og:url' content={canonicalUrl ? canonicalUrl : URL_ORIGIN} />
            <meta name="twitter:url" content={canonicalUrl ? canonicalUrl : URL_ORIGIN} />
            { canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : ''}
        </Helmet>
    )
})

MetaData.displayName = 'MetaData'

export default MetaData;
