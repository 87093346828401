import React, { createRef, memo, useEffect } from "react";
import { Fade } from "react-reveal";

import "./teamTimeline.sass";

export const TeamTimeline = memo(() => {
    const distance = '250px';
    const wrapper = createRef();

    const onScrollJourney = () => {
        const rect = wrapper?.current?.getBoundingClientRect();
        const scrollContainerPageY = rect?.top;
        //indicate when block is placed in the middle of screen when equal 0
        //10px - distance difference in num and container alignment
        const scrollIndicator = scrollContainerPageY - window.innerHeight / 2 + 10;

        if (scrollIndicator < 0) {
            // starting point to draw line
            let scrollPercentage = Math.abs(scrollIndicator / wrapper?.current?.scrollHeight);

            if (scrollPercentage < 0.855) {
                const height = wrapper?.current?.scrollHeight * scrollPercentage;
                const style = wrapper?.current?.style;
                style.setProperty('--height', `${height}px`);
            }
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', onScrollJourney);
        return () => document.removeEventListener('scroll', onScrollJourney);
    })

    return (
        <section className="team-timeline">
            <h1 className="section-title">We think you’ll like how we work…</h1>
            <div className="wrapper" ref={wrapper}>
                <div className="container left">
                    <Fade left={true} distance={distance}>
                        <div className="content">
                            <h2>Caring</h2>
                            <p>
                                We’re a people first business, so we start by getting an understanding
                                of your business and your ambitions, and what your customers want.
                                Then we put you at the centre of a strategy that is designed to shape success.{" "}
                            </p>
                        </div>
                    </Fade>
                </div>
                <div className="container right">
                    <Fade right={true} distance={distance}>
                        <div className="content">
                            <h2>Committed</h2>
                            <p>
                                We go all in to design and develop branding strategies that build business:
                                adding value wherever we can and taking nothing away.
                                You’ll see our commitment in our understanding, attention to detail and
                                focus on delivering quality in every way possible.
                            </p>
                        </div>
                    </Fade>
                </div>
                <div className="container left">
                    <Fade left={true} distance={distance}>
                        <div className="content">
                            <h2>Confident</h2>
                            <p>
                                With more than 20 years’ experience of building brands in a fast-paced,
                                rapidly evolving industry and across many market sectors, we know what it takes
                                to get your brand in the best possible shape, then take it even further.
                                Rest assured, whatever your business and whatever your ambitions, we can deliver.
                            </p>
                        </div>
                    </Fade>
                </div>
                <div className="container right">
                    <Fade right={true} distance={distance}>
                        <div className="content">
                            <h2>Connected</h2>
                            <p>
                                Our small, highly experienced team sits at the heart of a network evolved over 20 years in the industry.
                                That means we can call on exceptional insight and specialist expertise to execute any campaign.
                                It also means you only pay for what you need and nothing you don’t which is WIN/WIN for you.
                            </p>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    );
});
