import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { withRouter } from "react-router-dom";

import "./contactUsForm.scss";
import Field from "./Field";
import {
  VALIDATE_EMAIL,
  RECAPTCHA_SITE_KEY, VALIDATE_PHONE, NormilizePhone
} from "utils/index";
import {AWESOME_ROUTE, WORKSHEET_ROUTE, GENERAL_CONSULTATION_ROUTE, PRODUCTS_ROUTE} from "constants/routes";
import Button from "components/Button";
import { removeInlineStyles } from "utils/helpers";

const recaptchaRef = React.createRef();

class ContactForm extends Component {
  state = {
    values: {
      name: "",
      email: "",
      phone: "",
      message: ""
    },
    responseForm: {
      message: "",
      status: ""
    },
    errors: {
      name: "",
      email: "",
      phone: "",
      message: ""
    }
  };

  onChange = e => {
    const name = e.target.name.toLowerCase();
    const value = e.target.value;

    this.setState(prevState => ({
      values: {
        ...prevState.values,
        [name]: name === 'phone' ? NormilizePhone(value) : value
      },
      errors: {
        ...prevState.errors,
        [name]: ""
      }
    }));
  };

  validateValues = () => {
    const { values } = this.state;
    const errors = {};

    if (values.name.length === 0) {
      errors.name = "Username cannot be blank.";
    }

    if (!VALIDATE_EMAIL(values.email)) {
      errors.email = "Enter valid email.";
    }

    if (!VALIDATE_PHONE(values.phone)) {
      errors.phone = "Enter valid number.";
    }

    return errors;
  };

  onSubmit = e => {
    const { values } = this.state;
    const { subject, total, blocks, url, additional_tag } = this.props;
    e.preventDefault();

    const errors = this.validateValues();
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors: errors
      });
    } else {
      recaptchaRef.current.execute();

      values.subject = subject;

      if (typeof additional_tag === 'string' && additional_tag.length) {
        values.additional_tag = additional_tag;
      }


      if (subject === 'Define your project') {
        values.total = total;
        values.blocks = blocks.length !== 0 ? blocks : null;
      }

      if (subject === 'About Us' && sessionStorage.subject) {
        values.additional_tag = sessionStorage.subject;
      }

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(values)
      })
          .then((res) => {
            sessionStorage.clear();
            this.setState({
              values: {
                name: "",
                email: "",
                phone: "",
                message: ""
              }
            });
            if (this.props.match.url === PRODUCTS_ROUTE || this.props.match.url === GENERAL_CONSULTATION_ROUTE) {
              this.props.history.push(WORKSHEET_ROUTE)
            }
            else {
              this.props.history.push(AWESOME_ROUTE)
            }
            return console.log(res.statusText)
          })
          .catch(err => console.log(err))
    }
  };

  render() {
    const { values, errors, responseForm } = this.state;

    const {
      formType,
      twoColumn,
      buttonText,
      buttonType,
      textareaLabel,
      numberLabel,
      field_color,
      paragraph_color
    } = this.props;

    return (
        <form onSubmit={this.onSubmit} className="contact-form">
          <div className={`${twoColumn ? "flex" : ""}`}>
            <div className="left">
              <Field
                  className={buttonType === "dark" ? "light" : ""}
                  id="name"
                  labelText="Your Name"
                  type="text"
                  name="NAME"
                  value={values.name}
                  onChange={this.onChange}
                  error={errors.name}
                  field_color={field_color}
              />
              <Field
                  className={buttonType === "dark" ? "light" : ""}
                  id="email"
                  labelText="Your Email"
                  type="email"
                  name="EMAIL"
                  value={values.email}
                  onChange={this.onChange}
                  error={errors.email}
                  field_color={field_color}
              />
              <Field
                  className={buttonType === "dark" ? "light" : ""}
                  id="phone"
                  labelText={numberLabel}
                  type="tel"
                  name="PHONE"
                  value={values.phone}
                  onChange={this.onChange}
                  error={errors.phone}
                  field_color={field_color}
              />
              {formType === 'define' && (
                  <p style={{ color: paragraph_color ? paragraph_color : "" }} className={'defineParagraph'}>
                    This site is protected by reCAPTCHA and the Google Privacy Policy
                    and Terms of Service apply.
                  </p>
              )}
            </div>
            <div className="right">
              <div className="form-group">
              <textarea
                  id="message"
                  className={`form-control ${buttonType === "dark" ? "light" : ""
                  }`}
                  name="MESSAGE"
                  value={values.message}
                  onChange={this.onChange}
                  placeholder={textareaLabel}
                  style={{ backgroundColor: field_color ? field_color : '' }}
              />
                {errors.message ? (
                    <div className="invalid-feedback">{errors.message}</div>
                ) : null}
              </div>
              <div className={`text-wrap ${formType === 'define' ? 'defineWrap' : ''}`}>
                {formType !== 'define' && (
                    <div>
                      <p style={{ color: paragraph_color ? paragraph_color : "" }}>
                        This site is protected by reCAPTCHA and the Google Privacy Policy
                        and Terms of Service apply.
                      </p>
                    </div>
                )}
                <div className={`${formType === 'define' ? 'defineBtn' : ''}`}>
                  <Button
                      type="submit"
                      name="submit"
                      styleType={`btn-submit formEmailButton ordinaryBtn ${(buttonType && buttonType === "dark") ? "dark black" : "white-grad"}`}
                      disabled={responseForm.status === "success"}
                      style={{
                        backgroundColor: paragraph_color ? 'transparent' : '',
                        color: paragraph_color ? '#fff' : ''
                      }}
                      label={buttonText}
                  />
                  {responseForm.status === "success" && (
                      <div className="success-form"> {responseForm.message} </div>
                  )}
                  {responseForm.status === "error" && (
                      <div
                          className="error-form"
                          dangerouslySetInnerHTML={{ __html: removeInlineStyles(responseForm?.message) }}
                      />
                  )}
                  <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={RECAPTCHA_SITE_KEY}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
    );
  }
}

export default withRouter(ContactForm)
