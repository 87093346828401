import React, { useState } from 'react';
import {Link, useLocation} from 'react-router-dom';

import './categoryItem.scss';
import {removeInlineStyles} from "utils/helpers";


export const CategoryItem = ({ category, image, image_alt, title, description, link, onClick, date }) => {

    const [hovered, setHovered] = useState(false);

    const { pathname } = useLocation();

    const createMarkup = (description) => {
        return { __html: removeInlineStyles(description) };
    }

    const changeDateFormat = (date) => {
        if (!date) return
        const currentDate = new Date(date.replace(/-/g, "/"));
        const day = currentDate.toLocaleDateString('en-GB', { day: 'numeric' });
        const month = currentDate.toLocaleDateString('en-GB', { month: 'long' });
        const year = currentDate.toLocaleDateString('en-GB', { year: 'numeric' });

        let formattedDay;

        switch (day) {
            case '2':
            case '22': {
                formattedDay = `${day}nd`
                break;
            }
            case '3':
            case '23': {
                formattedDay = `${day}rd`
                break;
            }
            case '21':
            case '31': {
                formattedDay = `${day}st`
                break;
            }
            case '1': {
                formattedDay = `${day}st`
                break;
            }
            default: {
                formattedDay = `${day}th`
                break;
            }
        }

        const formattedDate = `${formattedDay} ${month}, ${year}`;

        return formattedDate;
    };

    return (
        <section className={`category-item ${pathname === '/blog' ? 'date' : ''}`} onClick={onClick}>
            <div className={`item__content ${hovered ? 'hovered' : ''}`}>
                <Link to={link} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                    <div className="content__image">
                        <img src={image} alt={image_alt} />
                        {category && <div className="content__btn">{category}</div>}
                    </div>
                </Link>

                <div className={'content__date'}>
                    Published: { changeDateFormat(date) }
                </div>

                <Link className="title__link" to={link} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                    <h2 className="content__title">{title}</h2>
                </Link>
                <div className="content__description" dangerouslySetInnerHTML={createMarkup(description)} />
                {/* {link} */}
            </div>
        </section>
    )
}
