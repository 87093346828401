import React, {memo} from 'react';

import './serviceConsultation.scss';

import { GENERAL_CONSULTATION_ROUTE } from '../../constants/routes';
import image from '../../assets/images/new/ServicesPage/Path2.png';
import Button from 'components/Button';

export const ServiceConsultation = memo(({title, description, btnLabel}) => {
    return (
        <section className="service-consultation">
            <div className="content">
                <div className="image-section">
                    <img src={image} alt="consultation image"/>
                </div>
                <div className="consultation-content">
                    <div className="consultation__title">
                        {title}
                    </div>
                    <div className="consultation__description">
                        {description}
                    </div>
                    <Button
                        styleType='consultation__link ordinaryBtn black'
                        label={btnLabel}
                        link={{ pathname: GENERAL_CONSULTATION_ROUTE }}
                    />
                </div>
            </div>
        </section>
    )
})

ServiceConsultation.displayName = 'ServiceConsultation';
