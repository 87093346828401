import { Tick } from 'components/Tick';
import React, {memo} from 'react';

import './itemsList.scss';

const MoreLink = props => {
    return (
        <a className="link" {...props}>{props.children}</a>
    );
}

export const ItemsList = memo( ({title, items, linkLabel, linkHref, color, className }) => {

    if (items !== undefined) {
        return (
            <section className={`items-section ${className}`}>
                <div className="content">
                    <div className="title">{ title }</div>
                    <ul className='benefits-list'>
                        {
                            items?.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <span>
                                            <Tick color={color} />
                                        </span>
                                        <span className='label'>{item.name}</span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    {linkLabel && <MoreLink href={linkHref}>{ linkLabel }</MoreLink>}
                </div>
            </section>
        )
    }
    return null;
})
