import React, {memo} from 'react';
import { Link } from 'react-router-dom';

import './button.scss';

export const Button = memo((props) => {
    const {
        label,
        type,
        name,
        styleType,
        handleClick,
        disabled,
        style,
        link,
        target
    } = props

    return (
        <button
            type={type ? type : 'button'}
            name={name}
            className={styleType}
            onClick={handleClick}
            disabled={disabled}
            style={style}
        >
            {!link &&  <div className="btnDiv">{label}</div>}
            {link && (<Link to={link} className="btnLink" target={target ? target : undefined}>{label}</Link>)}
        </button>
    );
})

Button.displayName = 'Button';

export default Button;
