import React, {memo} from "react";
import { Fade, Zoom } from "react-reveal";

import "./ourTools.sass";

import ourTools from "assets/images/new/Team Images/tools-skills-team.png";
import adobe from "assets/images/new/Our tools and skills/Adobe.png";
import laravel from "assets/images/new/Our tools and skills/Laravel 2.png";
import marvel from "assets/images/new/Our tools and skills/Marvel.png";
import googleAds from "assets/images/new/Our tools and skills/Google.png";
import hangOuts from "assets/images/new/Our tools and skills/Hangouts.png";
import sketch from "assets/images/new/Our tools and skills/Sketch.png";
import react from "assets/images/new/Our tools and skills/react.png";
import mailChimp from "assets/images/new/Our tools and skills/Mailchimp.png";
import semrush from "assets/images/new/Our tools and skills/Semrush.png";
import skype from "assets/images/new/Our tools and skills/Skype.png";
import {removeInlineStyles} from "utils/helpers";

export const OurTools = memo(({ title, description, apiContent }) => {

  const createMarkup = (content) => {
    return { __html: removeInlineStyles(content) };
  };

  return (
      <section className='our-tools' id='tools'>
        <div className='content'>
          <div className='info'>
            <Fade top={true} distance='300px'>
              <div className="content__wrapper">
                <h2>{title}</h2>
                {description}
                <div className='api-content' dangerouslySetInnerHTML={createMarkup(apiContent)}/>
              </div>
            </Fade>
            <div className='stack'>
              <Zoom>
                <div className='tool'>
                  <img src={adobe} alt='adobe' loading={'lazy'} />
                </div>
              </Zoom>

              <Zoom delay={300}>
                <div className='tool'>
                  <img src={laravel} alt='hexagon' loading={'lazy'} />
                </div>
              </Zoom>

              <Zoom delay={window.innerWidth < 1200 ? 300 : 600}>
                <div className='tool'>
                  <img src={marvel} alt='hexagon' />
                </div>
              </Zoom>

              <Zoom delay={window.innerWidth < 1200 ? 300 : 900}>
                <div className='tool'>
                  <img src={googleAds} alt='hexagon' loading={'lazy'} />
                </div>
              </Zoom>

              <Zoom delay={window.innerWidth < 1200 ? 300 : 1200}>
                <div className='tool'>
                  <img src={hangOuts} alt='hexagon' loading={'lazy'} />
                </div>
              </Zoom>

              <Zoom delay={window.innerWidth < 1200 ? 300 : 1500}>
                <div className='tool'>
                  <img src={sketch} alt='hexagon' loading={'lazy'} />
                </div>
              </Zoom>

              <Zoom delay={window.innerWidth < 1200 ? 300 : 1800}>
                <div className='tool'>
                  <img src={react} alt='hexagon' loading={'lazy'} />
                </div>
              </Zoom>

              <Zoom delay={window.innerWidth < 1200 ? 300 : 2100}>
                <div className='tool'>
                  <img src={mailChimp} alt='hexagon' loading={'lazy'} />
                </div>
              </Zoom>

              <Zoom delay={window.innerWidth < 1200 ? 300 : 2400}>
                <div className='tool'>
                  <img src={semrush} alt='hexagon' loading={'lazy'} />
                </div>
              </Zoom>

              <Zoom delay={window.innerWidth < 1200 ? 300 : 2700}>
                <div className='tool'>
                  <img src={skype} alt='hexagon' loading={'lazy'} />
                </div>
              </Zoom>
            </div>
          </div>
          <Fade bottom={true} distance='200px'>
            <div className='photo'>
              <img className='drop-shadow shadow-t' src={ourTools} alt='work process' loading={'lazy'} />
            </div>
          </Fade>
        </div>
      </section>
  );
});

OurTools.displayName = 'OurTools';
