import React from "react";
import { render } from "react-dom";

import App from "scenes/App/App";
import { SearchProvider } from "searchContext";

import * as serviceWorker from "./serviceWorker";
import './index.scss';

const root = document.getElementById("root");

const Main = () => {
    return (
        <SearchProvider>
            <App />
        </SearchProvider>
    )
}

render(<Main />, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
