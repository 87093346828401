import React, {memo} from 'react';

import './toTopButton.scss';

import { animateScroll as scroll } from "react-scroll";

import { ReactComponent as Arrow } from '../../assets/images/arrow-down.svg';

export const ToTopButton = memo(() => {

    const scrollToTop = () => {
        scroll.scrollToTop();
    }
    return (
        <div className="btn_to_top"
             onClick={scrollToTop}>
            <Arrow />
        </div>
    )
})

ToTopButton.displayName = 'ToTopButton';
