import React, {useState, useEffect, memo} from 'react';
import { useRouteMatch } from "react-router-dom";

import './ArticleCategories.scss';

import { CategoryItem, Columns } from 'components';
import { FilterTabs } from './FilterTabs';

export const ArticleCategories = memo(({data, articles, filterData, buttons, options}) => {
    const [articlesData, setArticlesData] = useState([]);
    const [filter, setFilter] = useState('');
    const { url } = useRouteMatch();

    const showArticles = (arr, Element) => {
        return arr?.map((item, i) => {
            return (
                <Element
                    key={i}
                    category={item.post.category}
                    image={item.post.main_image}
                    image_alt={item.post.main_image_alt_text}
                    title={item.post.title}
                    date={item.post.published_at}
                    description={item.author.author_description}
                    link={`${url}/${item.post.slug}`}
                />
            )
        })
    };

    const onFilterChange = (filter) => {
        setFilter(filter);
        setArticlesData(filterData(data, filter))
    };

    // used for separating articles by two columns according to the design
    const evenInsights = articlesData?.filter((e, i) => !(i % 2));
    const oddInsights = articlesData?.filter((e, i) => i % 2);

    useEffect(() => {
        const query = window.location.search.split('?tag=')[1];
        query ? setFilter(query) : setFilter('all');
    }, []);

    useEffect(() => {
        setArticlesData(articles);
    }, [articles]);

    return (
        <section className='article-categories'>
            <div className="content">
                <FilterTabs
                    filter={filter}
                    onFilterChange={onFilterChange}
                    buttons={buttons}
                    options={options}
                />
                {window.innerWidth >= 768 ?
                    <Columns
                        left={showArticles(filterData(evenInsights, filter), CategoryItem)}
                        right={showArticles(filterData(oddInsights, filter), CategoryItem)}
                    /> :
                    showArticles(filterData(articlesData, filter), CategoryItem)}
            </div>
        </section>
    )
})

ArticleCategories.displayName = 'ArticleCategories';
