import React from "react";

import './topSection.sass';
import arrowDown from "assets/images/new/Define Your Project/down-arrow.svg";

export const TopSection = () => {
  const scrollToNext = () =>
    window.scrollTo({ top: window.innerHeight, behavior: "smooth" });

  return (
    <div className="top-section">
      {/* <div className='directions'>Brand | Design | Web | Digital</div> */}
      <h1>
          Define<br/> your creative <br/> project
      </h1>
      <h2 className="subtitle">
          Every great project <br/> starts with a good brief
      </h2>
      <button className="button" onClick={scrollToNext}>
        Let’s define your project <img src={arrowDown} alt="arrow-down" />
      </button>
    </div>
  )
}
