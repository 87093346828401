import React, { memo } from "react";
import { Link } from "react-router-dom";

import "./footer.sass";
import { PRIVACY_POLICY_ROUTE } from "constants/routes";
import {useInView} from "react-intersection-observer";

export const Footer = memo(({ dark, color }) => {

    const { ref: inViewRef, inView } = useInView({
        triggerOnce: true,
        rootMargin: '200px'
    });

    return (
        <footer ref={inViewRef} className={`footer ${dark ? 'dark' : ''}`}>
            {inView && (
                <div className="footer__content">
                    <span style={{color: color ? '#fff' : '#7b868c'}}>©{new Date().getFullYear()} The Heighton Agency Limited</span>
                    <span style={{color: color ? '#fff' : '#7b868c'}}>Company No. 12632394</span>
                    <span style={{color: color ? '#fff' : '#7b868c'}}>VAT No. 350523234</span>
                    <span>
                      <Link to={PRIVACY_POLICY_ROUTE} style={{ color: color ? '#fff' : '#7b868c' }}>Privacy Policy</Link>
                  </span>
                </div>
            )}
        </footer>
    );
});

Footer.displayName = 'Footer';
