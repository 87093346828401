import React, {useState, useEffect, memo} from 'react';
import { Link } from 'react-router-dom';

import './subscribeUs.scss';

import SocialLinks from '../ContactUs/SocialLinks';
import { SubscriptionForm } from './SubscriptionForm';
import { PRIVACY_POLICY_ROUTE, COOKIE_POLICY_ROUTE } from 'constants/routes';
import {BASE_URL} from "constants/api";

export const SubscribeUs = memo(() => {
    const description = 'Subscribers to our newsletter have been rewarded with some fabulous hints, tips and advice to help drive informed decisions.';

    const [formImage, setFormImage] = useState();
    const [submitSuccess, setSubmitSuccess] = useState(false);

    useEffect(() => {
        fetch(`${BASE_URL}/subscribe-form-image`)
            .then((res) => res.json())
            .then((data) => {
                setFormImage(data.subscribe_form_image);
            });
    }, []);

    return (
        <section className='subscribe-us'>
            <div className="content">
                <div className="content__form">
                    {!submitSuccess ? (
                            <>
                                <h2 className="form__title">Want resource straight to your finger tips?</h2>
                                <div className="form__description">{description}</div>
                                <SubscriptionForm setSubmit={setSubmitSuccess} />
                                <div className="form__text">
                                    By completing this form, you agree to our <Link className="text__link" to={COOKIE_POLICY_ROUTE}>Terms of Service</Link> <br />
                                    and <Link className="text__link" to={PRIVACY_POLICY_ROUTE}>Privacy Policy</Link>.
                                </div>
                            </>
                        ) :
                        (
                            <>
                                <div className="form__title thank-you">Success! <br /> Thank you for subscribing</div>
                            </>
                        )
                    }
                    <SocialLinks
                        color={'#323e48'}
                        dark={true}
                        connected={true}
                    />
                </div>
                <div className="content__image">
                    <img src={formImage} alt="form-alt" loading={'lazy'}/>
                </div>
            </div>
        </section>
    )
})

SubscribeUs.displayName = 'SubscribeUs';
