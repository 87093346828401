export const VALIDATE_EMAIL = email => {
    let valid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return valid.test(String(email).toLowerCase())
};
export const VALIDATE_PHONE = phone => {
    // let valid = /^\+?([0-9]{4})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    // let valid2 = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    // let valid3 = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    //
    // if (phone.match(valid) || phone.match(valid2) || phone.match(valid3)) {
    //     console.log('phone valid!')
    //     return true;
    // }

    if (phone.length === 13) {
        return true;
    }
    else {
        return false;
    }
}

export const NormilizePhone = (value, maskLength = 13) => {
    const raw = value.replace(/\D+/g, '').split('')

    // '01435 692 023'

    if (raw.length > 5) {
        raw.splice(5, 0, ' ')
    }

    if (raw.length > 9) {
        raw.splice(9, 0, ' ')
    }

    if (raw.length > maskLength) {
        const formattedNumber = raw.map((item, index) => (index + 1) <= maskLength ? item : '')
            .join('')
            .trim()

        return `${formattedNumber}`
    }

    const formattedNumber = raw.join('').trim()

    return `${formattedNumber}`

}

export const RECAPTCHA_SITE_KEY = '6LcpCPwUAAAAAC1F-Cosdpm-wmEw6Y7v-nn-8Ehd';
