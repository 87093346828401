import React from 'react';

export const Linkedin = ({ color }) => {
    return (
        <svg width="67" height="59" viewBox="0 0 67 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <rect width="1920" height="10626" transform="translate(-474 -2421)" fill="white" />
                <path d="M16.7804 58.0527H50.16L66.8803 29.0527L50.16 0.0527344H16.7804L0.121094 29.0527L16.7804 58.0527Z" fill={color} />
                <path fillRule="evenodd" clipRule="evenodd" d="M48.6957 29.3437V39.8985H42.53V30.0528C42.53 27.5795 41.6407 25.8905 39.4063 25.8905C37.7042 25.8905 36.6919 27.029 36.2447 28.1299C36.0808 28.5218 36.0406 29.0677 36.0406 29.6204V39.8985H29.872C29.872 39.8985 29.9553 23.221 29.872 21.4944H36.0406V24.1034C36.0291 24.1234 36.0113 24.1434 35.9998 24.1633H36.0406V24.1034C36.8603 22.8514 38.3221 21.062 41.5982 21.062C45.6554 21.062 48.6957 23.6933 48.6957 29.3437ZM23.4234 12.624C21.3126 12.624 19.9336 13.9987 19.9336 15.8035C19.9336 17.5706 21.2718 18.9829 23.3424 18.9829H23.3803C25.532 18.9829 26.8702 17.5706 26.8702 15.8035C26.8294 13.9987 25.532 12.624 23.4234 12.624ZM20.2998 39.8985H26.4638V21.4944H20.2998V39.8985Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="1920" height="10626" fill="white" transform="translate(-474 -2421)" />
                </clipPath>
            </defs>
        </svg>

    )
}