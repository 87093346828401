import React from 'react';
import Slider from 'react-slick';
import { HashLink as Link } from "react-router-hash-link";

import {
    DEFINE_PROJECT_ROUTE,
    // HOME_ROUTE,
    // OUR_TEAM_ROUTE,
    SOLUTIONS_ROUTE,
    SERVICES_ROUTE,
    BRAND_ROUTE,
    ABOUTUS_ROUTE,
    PRODUCTS_ROUTE,
    NEWS_ROUTE,
    EDUCATION_ROUTE,
    GENERAL_CONSULTATION_ROUTE,
    CONTACT_ROUTE, OUR_TEAM_ROUTE
} from "constants/routes";

import './navSlider.sass';

const NavSlider = ({toggleMenu}) => {

    const settings = {
        dots: false,
        infinite: false,
        fade: true,
        // arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return (
        <section className="nav-slider">
            <Slider {...settings}>
                <div className='nav-slider__slide'>
                    <Link
                        to={`${SOLUTIONS_ROUTE}`}
                        onClick={toggleMenu}>
                        Case Studies
                    </Link>
                    <Link
                        to={`${SERVICES_ROUTE}`}
                        onClick={toggleMenu}>
                        Services
                    </Link>
                    <Link to={BRAND_ROUTE} onClick={toggleMenu}>
                        Our Brand
                    </Link>
                    <Link to={ABOUTUS_ROUTE} onClick={toggleMenu}>
                        About
                    </Link>
                    <Link to={OUR_TEAM_ROUTE} onClick={toggleMenu}>
                        Our Team
                    </Link>
                </div>
                <div className='nav-slider__slide'>
                    <Link to={`${PRODUCTS_ROUTE}`} onClick={toggleMenu}>
                        Solutions
                    </Link>
                    <Link to={`${EDUCATION_ROUTE}`} onClick={toggleMenu}>
                        Insights
                    </Link>
                    <Link to={`${NEWS_ROUTE}`} onClick={toggleMenu}>
                        Blog
                    </Link>

                    {/*<Link className="define_link" to={DEFINE_PROJECT_ROUTE} onClick={toggleMenu}>*/}
                    {/*    Pricing*/}
                    {/*</Link>*/}
                    <Link to={`${GENERAL_CONSULTATION_ROUTE}`} onClick={toggleMenu}>
                        Consultations
                    </Link>
                    <Link to={CONTACT_ROUTE} onClick={toggleMenu}>
                        Contact
                    </Link>
                </div>
            </Slider>
        </section>
    )
}

export default NavSlider;