import React, {memo, useState} from "react";
import Slider from "react-slick";
import { Fade } from "react-reveal";


import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./caseStudy.sass";
import {removeInlineStyles} from "utils/helpers";

export const CaseStudy = memo(({ id, left = true, right = false, caseStudy }) => {
  const distance = "100px";
  const label = document.querySelectorAll('section.case-study .slick-prev');

  const [currentTab, setTab] = useState(0);

  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true
  };

  if (currentTab > 0) {
    label.forEach(el => el.classList.add('hide'))
  } else {
    label.forEach(el => el.classList.remove('hide'))
  }

  const slides = caseStudy && caseStudy.studies.length > 0 ? [0, 1] : [0];

  return (
      <section className={`case-study`} id={id}>
        <Slider {...settings}>
          {slides.map((slide, index) => (
              <div className={`case-study-slide`} key={index}>
                <div
                    style={{
                      backgroundImage: window.innerWidth > 540 ? `url(${index === 0
                          ? !caseStudy.case_video && caseStudy?.case_image
                          : caseStudy?.studies.length > 0
                              ? !caseStudy?.studies[currentTab].study_video &&
                              caseStudy?.studies[currentTab].study_image
                              : null
                      })` : `url(${index === 0
                          ? !caseStudy.case_mobile_video && caseStudy?.case_mobile_image
                          : caseStudy?.studies.length > 0
                              ? !caseStudy?.studies[currentTab].study_mobile_video &&
                              caseStudy?.studies[currentTab].study_mobile_image
                              : null
                      })`
                    }}
                    className="slide-image"
                />
                { window.innerWidth > 540 ?
                    index === 0
                        ? caseStudy.case_video && (
                        <video autoPlay muted loop playsInline>
                          <source src={caseStudy.case_video} type="video/mp4" />
                        </video>
                    ) : caseStudy?.studies.length > 0
                            ? caseStudy?.studies[currentTab].study_video && (
                            <video autoPlay muted loop playsInline>
                              <source
                                  src={caseStudy?.studies[currentTab].study_video}
                                  type="video/mp4"
                              />
                            </video>
                        ) : null :
                    index === 0
                        ? caseStudy.case_mobile_video && (
                        <video autoPlay muted loop playsInline>
                          <source src={caseStudy.case_mobile_video} type="video/mp4" />
                        </video>
                    ) : caseStudy?.studies.length > 0
                            ? caseStudy?.studies[currentTab].study_mobile_video && (
                            <video autoPlay muted loop playsInline>
                              <source
                                  src={caseStudy?.studies[currentTab].study_mobile_video}
                                  type="video/mp4"
                              />
                            </video>
                        ) : null
                }
                <div className="content">
                  <Fade left={left} right={right} distance={distance}>
                    <h2 className="title">
                      {index === 0
                          ? caseStudy?.title
                          : caseStudy?.studies.length > 0
                              ? caseStudy?.studies[currentTab]?.title
                              : ""}
                    </h2>
                  </Fade>

                  <Fade left={left} right={right} distance={distance}>
                    <h3 className="subTitle">
                      {index === 0
                          ? caseStudy?.subtitle
                          : caseStudy?.studies.length > 0
                              ? caseStudy?.studies[currentTab].subtitle
                              : ""}
                    </h3>
                  </Fade>

                  {index === 1 && (
                      <div
                          className={'case-study-description'}
                          dangerouslySetInnerHTML={{
                            __html: removeInlineStyles(caseStudy?.studies[currentTab].description)
                          }}
                      />
                  )}

                  {index === 1 && (
                      <div className="tabs">
                        {caseStudy?.buttons?.map((e, index) => (
                            <button
                                className={`tab ${currentTab === index && "active"}`}
                                key={index}
                                onClick={() => setTab(index)}
                            >
                              {index === 0 && (
                                  <div className="lines">
                                    <div className="long" />
                                    <div className="long" />
                                    <div className="short" />
                                    <div className="short" />
                                  </div>
                              )}
                              {e.button}
                            </button>
                        ))}
                      </div>
                  )}
                </div>
              </div>
          ))}
        </Slider>
      </section>
  );
});
