import React, { memo } from "react";
import { Fade } from "react-reveal";

import "./memberTemplate.sass";
import linkedIn from "assets/images/new/Linkedin.svg";
import Button from "components/Button";
import {removeInlineStyles} from "utils/helpers";

const MailLink = props => {
  return (
    <a className="mailTo" {...props}>{props.children}</a>
  );
}

export const MemberTemplate = memo(
    ({
       id,
       link,
       color,
       name,
       title,
       description,
       button,
       img,
       tag,
       apiContent,
       formId,
       mailTo,
       text_below_button,
       setAdditionalTag,
       consultation__link = false
     }) => {

      const createMarkup = (description) => {
        return { __html: removeInlineStyles(description) };
      };

      return (
          <section className='member' id={id}>
            <div className='content'>
              <div className='text'>
                <Fade top={true} distance='200px'>
                  {link ? (
                      <div className='name'>
                        <a href={link}>
                          <div className="author__link">
                            <svg width="67" height="59" viewBox="0 0 67 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M16.7804 58.0527H50.16L66.8803 29.0527L50.16 0.0527344H16.7804L0.121094 29.0527L16.7804 58.0527Z" fill={color || '#323E48'}/>
                            </svg>
                            <img src={linkedIn} alt="lindek-id" loading={'lazy'} />
                          </div>
                        </a>
                        <h4>{name}</h4>
                      </div>
                  ) : (
                      <div className='name'>
                        <h4>{name}</h4>
                      </div>
                  )}
                  {title}
                  {description}
                  <div className='api-content' dangerouslySetInnerHTML={createMarkup(apiContent)}></div>
                </Fade>

                <Fade left={true} distance='200px'>
                  <div className="btn__wrapper">
                    {mailTo ?
                        <MailLink color={color} href={mailTo}>
                          <Button
                              style={{ backgroundColor: color, '--shadow-color': color }}
                              label={button}
                              styleType={`ordinaryBtn`}
                          />
                        </MailLink>
                        :
                        consultation__link
                            ?
                            <Button
                                style={{ backgroundColor: color, '--shadow-color': color }}
                                styleType="consultation__link ordinaryBtn"
                                link={consultation__link}
                                label={button}
                            />
                            :
                            <Button
                                styleType={`ordinaryBtn`}
                                style={{ backgroundColor: color, '--shadow-color': color }}
                                handleClick={() => {
                                  sessionStorage.subject = tag;
                                  const additionalTag = id === 'partners' ? 'SEO' : tag || id[0].toUpperCase() + id.slice(1);
                                  setAdditionalTag(additionalTag)
                                  const engage = document.getElementById(formId);
                                  engage.scrollIntoView({ behavior: "smooth" });
                                }}
                                label={button}
                            />
                    }
                    <h5>{text_below_button ? text_below_button : 'One hour 1-2-1 in person or Skype'}</h5>
                  </div>
                </Fade>
              </div>

              <Fade right={true} distance='400px'>
                <div className='photo'>
                  <img className='drop-shadow' src={img} alt={name} loading={'lazy'} />
                </div>
              </Fade>
            </div>
          </section>
      );
    }
);

MemberTemplate.displayName = 'MemberTemplate';
