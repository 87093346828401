import Button from 'components/Button';
import React, {memo} from 'react';

import { GENERAL_CONSULTATION_ROUTE } from '../../constants/routes';
import './ArrangeConsultation.scss';
import {removeInlineStyles} from "utils/helpers";
import {useInView} from "react-intersection-observer";

export const ArrangeConsultation = memo(({ title, description, button_label, action_url }) => {
    const createMarkup = (content) => {
        return { __html: removeInlineStyles(content) };
    };

    const { ref: inViewRef, inView } = useInView({
        triggerOnce: true,
        rootMargin: '200px'
    });

    return (
        <section className="consultation-section" ref={inViewRef}>
            <div className="content" style={{ display: !inView ? 'none' : null }}>
                <div className="title">
                    <h2> {title} </h2>
                </div>
                <div className="description" dangerouslySetInnerHTML={createMarkup(description)}></div>
                <div className="consultation_btn">
                    <Button
                        styleType='btn ordinaryBtn black'
                        label={button_label}
                        link={action_url ? action_url : GENERAL_CONSULTATION_ROUTE}
                    />
                </div>
            </div>
        </section>
    )
})

ArrangeConsultation.displayName = 'ArrangeConsultation';
