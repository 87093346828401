import Button from 'components/Button';
import { GENERAL_CONSULTATION_ROUTE } from 'constants/routes';
import React, {memo} from 'react';

import './solutionPreview.scss';
import {removeInlineStyles} from "utils/helpers";


export const SolutionPreview = memo(({ logo, logo_shadow, button_url, logo_description, description, link, image, image_alt, image_shadow, categories, button_label }) => {
    const createMarkup = (content) => {
        return { __html: removeInlineStyles(content) };
    };

    return (
        <section className="solution-preview">
            <div className="content">
                <div className="content__description">
                    <div className="company">
                        <div className="company__logo">
                            <img src={logo} alt={'company logo'} loading={'lazy'} style={{filter: logo_shadow ? '' : 'unset'}}/>
                        </div>
                        <div className="company__description" dangerouslySetInnerHTML={createMarkup(logo_description)}/>
                    </div>
                    <ul className="solution__types">
                        {categories?.map((item, idx) => {
                            return (
                                <li key={idx}>{item.name}</li>
                            )
                        })}
                    </ul>
                    <div className="solution__description" dangerouslySetInnerHTML={createMarkup(description)}/>
                    <div className="solution__link">
                        {link}
                    </div>
                    <Button
                        styleType='consultation__link ordinaryBtn black'
                        label={button_label}
                        link={button_url ? button_url : GENERAL_CONSULTATION_ROUTE}
                    />
                </div>
                <div className="content__image">
                    <img src={image} loading={'lazy'} alt={image_alt} style={{filter: image_shadow ? '' : 'unset'}}/>
                </div>
            </div>
        </section>
    )
})

SolutionPreview.displayName = 'SolutionPreview';
