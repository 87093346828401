import React, {memo, useState} from 'react';

import './subscriptionForm.scss';
import Field from '../../ContactUs/Field';
import Button from 'components/Button';
import {BASE_URL} from "constants/api";

export const SubscriptionForm = memo(({ setSubmit }) => {
    const [email, setEmail] = useState('');

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        try {
            await fetch(`${BASE_URL}/enquiry/form/signup`, {
                method: 'POST',
                body: JSON.stringify({
                    email: email
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            setSubmit(true);
        }
        catch (error) {
            alert(`Submitting Email Error - ${error}`);
        }
    }

    return (
        <form className="subscribe-form" onSubmit={handleSubmit}>
            <Field
                labelText='Your Email'
                type="email"
                name="EMAIL"
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
            <Button
                styleType='submit__btn ordinaryBtn green'
                type="submit"
                name="submit"
                label="Sign me up!"
            />
        </form>
    )
})

SubscriptionForm.displayName = 'SubscriptionForm';
