import React, { memo } from "react";
import { Fade } from "react-reveal";

import ContactForm from "components/ContactUs/ContactForm";

import "./contactUs.scss";
import SocialLinks from "components/ContactUs/SocialLinks";
import { BASE_URL } from "constants/api";

const ContactUs = memo((props) => {
  const {
      id,
      title,
      mainColor,
      tel,
      type,
      description,
      color,
      buttonText,
      buttonType,
      subject,
      additional_tag,
      textareaLabel,
      numberLabel,
      connected,
      number_color,
      field_color,
      paragraph_color
  } = props

  return (
      <section className="contact-us" id={id || "contact-us"}>
        <div className="body-content">
          <div className="container">
            <div className="contact-form_wrap">
              <Fade delay={500}>
                <div className="left-side">
                  <h2
                      className="title"
                      style={{ color: mainColor ? mainColor : "" }}
                  >
                    {title}
                  </h2>

                  <h2 className={`${tel ? "phone" : "type"}`}>
                    <span />
                    {tel && <a href="tel:01435 692 023" style={{ color: number_color ? number_color : '' }}>01435 692 023</a>}
                    {type && <span>1-2-1 consultation</span>}
                  </h2>

                  {description}

                  <SocialLinks
                      color={color}
                      dark={!!mainColor}
                      connected={connected}
                  />
                </div>
              </Fade>
              <Fade delay={1000}>
                <div className="right-side">
                  <ContactForm
                      subject={subject}
                      additional_tag={additional_tag}
                      color={color}
                      buttonText={buttonText}
                      buttonType={buttonType}
                      textareaLabel={textareaLabel}
                      numberLabel={numberLabel}
                      url={`${BASE_URL}/enquiry/form`}
                      field_color={field_color}
                      paragraph_color={paragraph_color}
                  />
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
  );
})

ContactUs.displayName = 'ContactUs';

export default ContactUs
