import React, {memo} from 'react';
import { Link } from 'react-router-dom';

import './mediaSection.scss';

export const MediaSection = memo(({ image, image_alt, video, youtube_link, block_shadow, link }) => {

    return (
        <section className="media-section">
            <div className={`content ${block_shadow ? 'block-shadow' : ''}`}>
                <div className={`media-content ${(image && !video && !youtube_link) ? 'with-image' : ''} ${link ? 'with-link': ''}`} style={{ filter: block_shadow ? '' : 'unset'}}>
                    {image && !video && !youtube_link && <img src={image} alt={image_alt} />}
                    {video && !youtube_link &&
                        <video autoPlay muted loop playsInline>
                            <source src={video} type="video/mp4" />
                        </video>}
                    {youtube_link &&
                        <div className="video__wrapper">
                            <iframe width='100%' title='youtube_video' src={youtube_link} ></iframe>
                        </div>}
                </div>
                {link && (<Link to={link} className="media-link" />)}
            </div>
        </section>
    )
})

MediaSection.displayName = 'MediaSection';
