import React, {memo, useEffect} from "react";

import './preloader.sass'
import { ReactComponent as Logo } from "assets/images/new/Brand assets/logo-only.svg";

export const Preloader = memo(({ visible }) => {
  const anchorHandler = () => {
    if (window.history) {
      window.history.scrollRestoration = 'manual';
    };

    window.onload = function () {
      window.scrollTo(0, 0);
    };

    setTimeout(() => {
      const element = document.getElementById(window.location.hash.replace("#", ""));

      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.getBoundingClientRect().top : 0
      });
    }, 100);
  }

  useEffect(() => {
    anchorHandler();
    window.addEventListener("load", anchorHandler);
    return () => window.removeEventListener("load", anchorHandler);
  }, []);

  return (
      <>
        {visible ? (
            <div className="preloader">
              <Logo />
            </div>
        ) : null}
      </>
  );
});

Preloader.displayName = 'Preloader';
