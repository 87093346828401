import React, {memo} from 'react';

import './columns.scss';

export const Columns = memo(({ left, right }) => {
    return (
        <div className='columns-container'>
            <div className="left-column">
                {left}
            </div>
            <div className="right-column">
                {right}
            </div>
        </div>
    );
})

Columns.displayName = 'Columns';
