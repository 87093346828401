import React from "react";
import {Fade, Zoom} from "react-reveal";
import star from "assets/images/new/Client Commitment/Star.svg";

import './ReviewStars.sass';

export const ReviewsStars = ({ reviewCount }) => {
    const distance = "100px";

    return(
        <>
            <Fade right={true} distance={distance}>
                <div className='review'>
                    <h2 className='number'>{reviewCount}</h2>
                    <span>Google Reviews and growing</span>
                    <div className='stars'>
                        <Zoom delay={300}>
                            <img src={star} alt='star' />
                        </Zoom>
                        <Zoom delay={500}>
                            <img src={star} alt='star' />
                        </Zoom>
                        <Zoom delay={700}>
                            <img src={star} alt='star' />
                        </Zoom>
                        <Zoom delay={900}>
                            <img src={star} alt='star' />
                        </Zoom>
                        <Zoom delay={1200}>
                            <img src={star} alt='star' />
                        </Zoom>
                    </div>
                </div>
            </Fade>
        </>
    )
}