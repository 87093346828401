import React, {memo} from 'react';
import './Author.scss';

import linkedIn from "assets/images/new/Linkedin.svg";

export const Author = memo(({ main_image, main_image_alt, main_image_shadow, image, mobile_image, link, name, date, category, description }) => {
    const changeDateFormat = (date) => {
        const currentDate = new Date(date.replace(/-/g, "/"));
        const day = currentDate.toLocaleDateString('en-GB', { day: 'numeric' });
        const month = currentDate.toLocaleDateString('en-GB', { month: 'long' });
        const year = currentDate.toLocaleDateString('en-GB', { year: 'numeric' });

        let formattedDay;

        switch (day) {
            case '2':
            case '22': {
                formattedDay = `${day}nd`
                break;
            }
            case '3':
            case '23': {
                formattedDay = `${day}rd`
                break;
            }
            case '21':
            case '31':{
                 formattedDay = `${day}st`
                break;
            }
            case '1': {
                formattedDay = `${day}st`
                break;
            }
            default: {
                formattedDay = `${day}th`
                break;
            }
        }

        const formattedDate = `${formattedDay} ${month}, ${year}`;

        return formattedDate;
    };

    return (
        <section className='author-section'>
            <div className="content">
                <div className="content__left">
                    <img src={main_image} alt={main_image_alt} style={{ filter: main_image_shadow ? '' : 'unset'}}/>
                    <div className="content__btn">{category}</div>
                </div>
                <div className="content__right">
                    <div className="author-image">
                        <img src={ window.innerWidth < 992 ? mobile_image : image} alt="author image" />
                    </div>
                    <div className="author__info">
                        <div className="author__link">
                            <a href={link} target="_blank">
                                <svg width="67" height="59" viewBox="0 0 67 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.7804 58.0527H50.16L66.8803 29.0527L50.16 0.0527344H16.7804L0.121094 29.0527L16.7804 58.0527Z" fill={'#93D500'}/>
                                </svg>
                                <img src={linkedIn} alt="linked-in-logo" />
                            </a>
                        </div>
                        <div className="info__content">
                            <div className="name">Author: <span>{name}</span></div>
                            {date && <div className="published-data">{`Published: ${changeDateFormat(date)}`}</div>}
                        </div>
                    </div>
                    <div className="author-description">
                        {description}
                    </div>

                </div>
            </div>
        </section>
    )
})

Author.displayName = 'Author';
