import React, { useEffect, useState } from "react";

import "./App.sass";

import { Routes } from "scenes/App/routes";
import { Preloader } from "components";

const App = () => {
  const [isLoading, setLoading] = useState(true);
  const handlePreloadChange = () => {
      setLoading(!isLoading);

      let cookieBot = document.createElement('script');
      cookieBot.type = 'text/javascript';
      cookieBot.src = 'https://consent.cookiebot.com/uc.js';
      cookieBot.id = 'Cookiebot';
      cookieBot.dataset.cbid = '1c4ea472-0a01-4dde-9acb-c7f3c28bbb8d';
      cookieBot.dataset.blockingmode = 'auto';

      let CookieDeclaration = document.createElement('script');
      CookieDeclaration.type = 'text/javascript';
      CookieDeclaration.src = 'https://consent.cookiebot.com/1c4ea472-0a01-4dde-9acb-c7f3c28bbb8d/cd.js';
      CookieDeclaration.async = true;


      document.head.appendChild(cookieBot);
      document.head.appendChild(CookieDeclaration);
  };

  useEffect(() => {
    window.addEventListener("load", handlePreloadChange);
    return () => window.removeEventListener("load", handlePreloadChange);
  }, []);

  return (
      <div className='App'>
        {/*<CookiesPopUp />*/}
        <Preloader visible={isLoading} />
        <Routes />
      </div>
  );
};

export default App;
