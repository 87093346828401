import React, {useState, useEffect, useContext, useRef, useLayoutEffect} from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Link as RoutLink, useLocation, useHistory } from "react-router-dom";
import { useScrollToElement } from "utils/hooks";

import {
  DEFINE_PROJECT_ROUTE,
  // HOME_ROUTE,
  // OUR_TEAM_ROUTE,
  SOLUTIONS_ROUTE,
  SERVICES_ROUTE,
  BRAND_ROUTE,
  ABOUTUS_ROUTE,
  PRODUCTS_ROUTE,
  NEWS_ROUTE,
  EDUCATION_ROUTE,
  GENERAL_CONSULTATION_ROUTE,
  CONTACT_ROUTE, OUR_TEAM_ROUTE,
  // SEARCH_RESULTS_ROUTE
} from "constants/routes";
import SocialLinks from "components/ContactUs/SocialLinks";
import NavSlider from "./NavSlider/navSlider";
import { BASE_URL } from "constants/api";
import { SearchContext } from "searchContext";
import Button from "components/Button";

import "./menu.sass";

export const Menu = ({ visible, toggleMenu }) => {
  const location = useLocation();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [value, setValue] = useState('');
  const [active, setActive] = useState(false);
  // eslint-disable-next-line
  const [searchData, setSearchData] = useContext(SearchContext);

  useScrollToElement();

  const toggleActive = () => {
    setActive(!active);
  }

  const onSearch = (e) => {
    e.preventDefault();

    if (value === '') {
      return;
    }

    fetch(`${BASE_URL}/search/?filter_text=${value.replaceAll(/['"]+/g, '&apos;')}`)
      .then((res) => res.json())
      .then((res) => {
        setSearchData(res.items);
        history.push('/search-results', { value: value.replaceAll(/['"]+/g, '&apos;') });
        toggleActive();
        toggleMenu();
        setValue('')
      })
      .catch(err => console.log(err))
  }

  const productLinks = (arr) => {
    return arr?.map(item => {
      return (
        <Link className="product__link"
          key={item.id}
          onClick={toggleMenu}
          style={{ color: item.color }}
          to={{
            pathname: `${PRODUCTS_ROUTE}/${item.slug}`
          }}>
          {item.name}
        </Link>
      )
    })
  }

  const onValueChange = (e) => {
    setValue(e.target.value);
  }

  const defineLocation = location.pathname === DEFINE_PROJECT_ROUTE;

  useEffect(() => {
    fetch(`${BASE_URL}/products`)
      .then((res) => res.json())
      .then((data) => {
        setData(data.products.filter(item => item.show_in_menu));
      });
  }, []);

  useEffect(() => {
    const header = document.querySelector('header.header');
    const menu = document.querySelector('.menu');

    if (menu.classList.contains('active')) {
      header.addEventListener("mouseover", () => {
        header.classList.add("active-menu");
      })
      header.addEventListener("mouseout", () => {
        header.classList.remove("active-menu");
      })
    } else if (header.classList.contains('active-menu')) {
      header.classList.remove("active-menu");
    }
  });

  return (
    <section className={`menu ${visible ? " active" : ""}`}>
      <div className='define' style={{ visibility: defineLocation ? 'hidden' : 'visible' }}>
        <RoutLink to={'#contact-us'}>
          <Button
            type='button'
            styleType='button ordinaryBtn' //animate__animated animate__pulse animate__slower
            handleClick={toggleMenu}
            label={'Get Started'}
            link={DEFINE_PROJECT_ROUTE}
          />
        </RoutLink>
      </div>

      <div className="search-section">
        <form onSubmit={e => { onSearch(e); }}>
          <div
            className={window.innerWidth < 1280 && active ? "search__field active" : "search__field"}
            onClick={() => {
              if (!active) toggleActive()
            }}>
            <input
              type="text"
              placeholder={'Search: ‘web design’ or ‘brand building’'}
              value={value.replaceAll('&apos;', '\'')}
              onChange={onValueChange}
              className={window.innerWidth < 1280 && active ? "search-input active" : "search-input"}
            />
            <input className={window.innerWidth < 1280 && active ? "submitButton active" : "submitButton"} type="submit" value='' />
            <div className={window.innerWidth < 1280 && active ? "close-icon active" : "close-icon"} onClick={() => toggleActive()}></div>
          </div>
        </form>
        <div className="section__title"><span>Your Perfect </span> Branding Partner</div>
        <NavSlider toggleMenu={toggleMenu} />
      </div>
      <nav className='navigation'>
        <div className='left'>
          <Link
            to={`${SOLUTIONS_ROUTE}`}
            onClick={toggleMenu}>
            Case Studies
          </Link>
          <Link
            to={`${SERVICES_ROUTE}`}
            onClick={toggleMenu}>
            Services
          </Link>
          <Link to={BRAND_ROUTE} onClick={toggleMenu}>
            Our Brand
          </Link>
          <Link to={ABOUTUS_ROUTE} onClick={toggleMenu}>
            About
          </Link>
          <Link to={OUR_TEAM_ROUTE} onClick={toggleMenu}>
            Our Team
          </Link>
        </div>
        <div className="center">
          <div className="products-in-menu">
            <div className="products">
              {productLinks(data)}
            </div>
          </div>
          {/*<SocialLinks*/}
          {/*  maincolor='#323e48'*/}
          {/*  color={"#a2a9ad"}*/}
          {/*/>*/}
        </div>
        <div className='right'>
          <Link to={`${EDUCATION_ROUTE}`} onClick={toggleMenu}>
            Insights
          </Link>
          <Link to={`${NEWS_ROUTE}`} onClick={toggleMenu}>
            Blog
          </Link>
          <Link to={`${PRODUCTS_ROUTE}`} onClick={toggleMenu}>
            Solutions
          </Link>
          <Link to={`${GENERAL_CONSULTATION_ROUTE}`} onClick={toggleMenu}>
            Consultations
          </Link>
          <Link to={CONTACT_ROUTE} onClick={toggleMenu}>
            Contact
          </Link>
          {/*<Link className="define_link" to={DEFINE_PROJECT_ROUTE} onClick={toggleMenu}>*/}
          {/*  Pricing*/}
          {/*</Link>*/}
        </div>
      </nav>
    </section>
  );
};
