import React, {memo} from "react";

import { MemberTemplate } from "components/Team";

export const Team = memo(({ teamData, setAdditionalTag }) => {
  return (
      <>
        {teamData?.map((person, key) => {
            return (
                <MemberTemplate
                    key={key}
                    id={person?.title?.split(' ')?.[0]?.toLowerCase()}
                    link={person?.linkedin_url}
                    color={person?.call_to_action_button_color}
                    name={person?.title}
                    title=''
                    description=''
                    apiContent={person?.description}
                    button={person?.call_to_action_button_text}
                    img={person?.image}
                    formId='engage'
                    setAdditionalTag={setAdditionalTag}
                    tag={`Brand & Design Consultation ${person?.title}`}
                    text_below_button={person?.text_bellow_call_to_action}
                />
            )
        } )}
      </>
  );
});

Team.displayName = 'Team';
