import React, {useRef, useEffect, memo} from 'react';
import './BottomFormSection.scss';

import ContactUs from "components/ContactUs/ContactUs";
import {Footer} from 'components';
import hexagon from 'assets/images/new/form_hexagon.svg';
import cross from 'assets/images/new/form_cross.svg';
import triangle from 'assets/images/new/form_triangle.svg';

export const BottomFormSection = memo(({ title, color, paragraph_color, number_color, field_color, buttonText, subject, additional_tag }) => {
    const bottomContainer = useRef();

    const scrollHandler = () => {
        const header = document.querySelector("header.header");

        const rect = bottomContainer?.current?.getBoundingClientRect();
        const scrollContainerPageY = rect?.top;
        const btn = document.querySelector('.btn_to_top');

        if (scrollContainerPageY < 60) {
            if (header.classList.contains("black")) {
                header.classList.remove("black");
            }
        } else {
            header.classList.add("black");
        }

        if (scrollContainerPageY < 650) {
            if (btn) {
                btn.classList.add('white');
            }
        } else {
            if (btn) {
                btn.classList.remove('white');
            }
        }
    }

    useEffect(() => {
        document.addEventListener("scroll", scrollHandler);
        return () => document.removeEventListener("scroll", scrollHandler);
    });

    return (
        <section className="bottom__form-section" id='bottom-form' ref={bottomContainer} style={{ backgroundColor: color ? color : '#323e48'}}>
            <img style={{opacity: color ? 0.3 : ''}} loading={'lazy'} className='form_hexagon' src={hexagon} alt="hexagon alt" />
            {/*<img style={{opacity: color ? 0.3 : ''}} loading={'lazy'} className='form_cross' src={cross} alt="cross alt" />*/}
            <img style={{opacity: color ? 0.3 : ''}} loading={'lazy'} className='form_triangle' src={triangle} alt="triangle alt" />
            <ContactUs
                title={title ? title : <>Ready to<br />make contact?</>}
                tel={true}
                subject={subject}
                description={
                    <p style={{ color: paragraph_color ? paragraph_color : '', maxWidth: 500 }}>
                        If you share our belief that a partnership approach is the most effective, efficient way to deliver results over the long term, evolving and growing your business along the way, we should talk.
                    </p>
                }
                additional_tag={additional_tag}
                buttonText={window.innerWidth >= 1280 ? buttonText ? buttonText : 'Send Enquiry' : buttonText ? buttonText : 'Send Your Enquiry'}
                numberLabel={"Phone number"}
                textareaLabel={'Tell us more…'}
                connected={true}
                number_color={number_color}
                field_color={field_color}
                paragraph_color={paragraph_color}
            />
            <Footer color={color} />
        </section>
    )
})

BottomFormSection.displayName = 'BottomFormSection';
