import React, { useEffect, useState } from "react";
import {Link, useLocation, useHistory, Switch, Redirect} from "react-router-dom";

import {
  BRAND_CONSULTATION_ROUTE,
  DEFINE_PROJECT_ROUTE, DIGITAL_CONSULTATION_ROUTE,
  GENERAL_CONSULTATION_ROUTE,
  HOME_ROUTE,
  OUR_TEAM_ROUTE, PRODUCTS_ROUTE, WEB_CONSULTATION_ROUTE
} from "constants/routes";
import { Menu } from "components/index";
import { animateScroll as scroll } from "react-scroll";

import "assets/style/hamburger.css";
import "./header.sass";
import Button from "components/Button";

export const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const defineLocation = location.pathname === DEFINE_PROJECT_ROUTE;

  const [isActive, setActive] = useState(false);
  const toggleMenu = () => {
    setActive(!isActive);

    const header = document.querySelector('header.header');

    if (header.classList.contains('active')) {
      header.classList.remove('active');
    }
  }

  const engageHandler = () => {
    if (location.pathname === OUR_TEAM_ROUTE) {
      document.getElementById("engage").scrollIntoView({ behavior: "smooth" });
    } else {
      history.push(`${OUR_TEAM_ROUTE}#engage`);
    }

    if (isActive) {
      toggleMenu();
    }
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
    if (isActive) {
      toggleMenu();
    }
  }

  const defineHandler = () => {
    if (isActive) {
      toggleMenu();
    }
  }

  const scrollHandler = () => {
    const header = document.querySelector('header.header');
    const sticky = header.offsetTop;
    let cursorPosition = window.pageYOffset;

    if (window.pageYOffset > sticky) {
      header.classList.add("active");
    }
    setTimeout(() => {
      if (cursorPosition === window.pageYOffset) {
        header.classList.remove("active");
      }
    }, 1000);
  }

  useEffect(() => {
    const header = document.querySelector('header.header');
    const menu = document.querySelector('.menu');

    header.addEventListener("mouseover", () => {
      if (!menu.classList.contains('active')) {
        header.classList.remove("active-menu");
      }
      header.classList.add("active");
    })
    header.addEventListener("mouseout", () => {
      header.classList.remove("active");
    })

  });

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener("scroll", scrollHandler);
  });

  useEffect(() => {
    if (isActive) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "";

  }, [isActive]);

  const isConsultation = () => {
    switch (location.pathname) {
      case GENERAL_CONSULTATION_ROUTE:
        return true;
      case BRAND_CONSULTATION_ROUTE:
        return true;
      case WEB_CONSULTATION_ROUTE:
        return true;
      case DIGITAL_CONSULTATION_ROUTE:
        return true;

      default:
        return false
    }
  }

  const isSolutions = () => {
    return location.pathname?.includes(PRODUCTS_ROUTE)
  }

  const isDefine = () => {
    switch (location.pathname) {
      case DEFINE_PROJECT_ROUTE:
        return true;

      default:
        return false
    }
  }

  return (
      <>
        <Switch>
          <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />
        </Switch>
        <header className='header' id={`${isActive ? "no-blur" : ""}`}>
          <Link to={HOME_ROUTE}>
            <div className='logo' onClick={scrollToTop} />
          </Link>
          <div className={`contact__info ${isActive ? 'active' : ''}`}>
            <div className='contact__links'>
              <span className='links__text'>Let’s Talk</span>
              <a className="tel__link" href="tel:01435 692 023">(0) 1435 692 023</a>
              <span className='separator__span'>|</span>
              <a className="tel__link tel__link--main" href="tel:01435 692 023">Let's Chat</a>
            </div>
          </div>
          <div className='controls'>
            {!isDefine() && (
                <div className='engage' style={{ display: defineLocation ? 'block' : '' }}>
                  <Button
                      type='button'
                      styleType='button ordinaryBtn' // animate__animated animate__pulse animate__slower
                      handleClick={() => engageHandler()}
                      label={`Arrange your Consultation`}
                  />
                </div>
            )}

            { isConsultation() === false && (
                <div className='define' style={{ display: defineLocation ? 'none' : '' }}>
                  <Link to={isSolutions() ? '#contact-us' : DEFINE_PROJECT_ROUTE}>
                    <Button
                        type='button'
                        styleType='button ordinaryBtn black' // animate__animated animate__pulse animate__slower
                        handleClick={defineHandler}
                        label={isSolutions() ? `Let's chat` : 'Get Started'}
                    />
                  </Link>
                </div>
            )}

            <div
              className={`hamburger hamburger--spin js-hamburger ${isActive && "is-active"}`}
              onClick={toggleMenu}
            >
              <span className='hamburger-box'>
                <span className='hamburger-inner' />
              </span>
            </div>
          </div>
        </header>
        <Menu visible={isActive} toggleMenu={toggleMenu} />
      </>
  );
};
