import React, {useRef, useEffect, useState, memo} from "react";

import "./client.sass";
import { HeroX } from "./HeroX";

export const Client = memo(({review}) => {
  const clientContainer = useRef();
  const [state, setState] = useState("top");

  const scrollHandler = () => {
    const anchors = document.querySelector("section.anchors");
    const rect = clientContainer?.current?.getBoundingClientRect();
    const scrollContainerPageY = rect?.top;

    const height = (clientContainer?.current?.clientHeight - 50) * -1;
    const header = document.querySelector("header.header");

    if (scrollContainerPageY < 470 && scrollContainerPageY - 470 > height) {
      anchors && anchors.classList.add("black");
    } else {
      anchors && anchors.classList.remove("black");
    }

    if (scrollContainerPageY < 31 && scrollContainerPageY > height) {
      if (!header.classList.contains("black")) {
        header.classList.add("black");
      }
    } else {
      header.classList.remove("black");
    }


    if (window.innerWidth >= 350) {
      const heightX = document.querySelector('.hero-x').offsetHeight;
      const figures = document.querySelector('.clients-figures');
      const top = clientContainer?.current?.getBoundingClientRect().top;

      let bottom = 650;
      if (window.innerWidth < 1530 && window.innerWidth >= 992) {
        bottom = 450
      }
      if (window.innerWidth < 992) {
        bottom = 50
      }

      if (top < 150 && top > (heightX * -1 - bottom)) {
        if (!figures.classList.contains('in')) {
          figures.classList.add('in')
        }

        if (top > heightX * -1 + 200) {
          if (state !== "top") {
            setState('top')
          }
        } else {
          if (state !== 'full') {
            setState('full');
          }
        }
      } else {
        if (figures.classList.contains('in')) {
          figures.classList.remove('in')
          setState("none");
        }
      }
    }

  };

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);
    return () => document.removeEventListener("scroll", scrollHandler);
  });

  return (
      <section className='client' ref={clientContainer} id='client-commitment'>
        <div className="wrapper">
          <HeroX
              id='client-commitment'
              state={state}
              title={"Our commitment to you"}
              subtitle={
                <React.Fragment>
                  <h2>
                    We commit to adding value to your business
                    and your brand through <b>effective communication,
                    clear understanding</b> and a <b>deadline-driven approach</b>.
                  </h2>
                  <p>We’re confident we can deliver<br />because we already have.</p>
                </React.Fragment>
              }
              figures={true}
              stars={true}
              visible={window.innerWidth < 1530 || state === 'top'}
              review={review}
          />
          <HeroX
              title={null}
              state='full'
              subtitle={
                <React.Fragment>
                  <h2>
                    Over and over again, our team has created results-driven,
                    tailored solutions for <b>multi-million pound companies</b>,
                    for <b>SMEs</b> and <b>MSBs, investment-backed start-ups</b> and <b>local businesses</b> with an open, ambitious growth mindset.
                  </h2>
                  <p>
                    If you share our belief that a partnership approach is the most
                    effective, efficient way to deliver results over the long term,
                    evolving and growing your business along the way, we should talk.
                  </p>
                </React.Fragment>
              }
              figures={window.innerWidth < 1530}
              stars={false}
              visible={state === 'full' || state === 'top'}
              left={false}
              bottom={true}
          />
        </div>
      </section>
  );
});
