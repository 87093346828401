import React, {memo, useEffect, useState} from "react";

import { TopSection } from "scenes/DefineProject/TopSection";
import { Services } from "scenes/DefineProject/Services";
import { BottomSection } from "scenes/DefineProject/BottomSection";
import { BASE_URL } from "constants/api";
import MetaData from "components/MetaData";

import "./defineProject.sass";
import {URL_ORIGIN} from "utils/helpers";
import {DEFINE_PROJECT_ROUTE} from "constants/routes";

export const DefineProject = memo(() => {
    const [data, setData] = useState([])
    const textareaLabel = 'Tell us more…';
    const numberLabel = "Your Number*";

    useEffect(() => {
        fetch(`${BASE_URL}/define_project_sections`)
            .then(res => res.json())
            .then(info => {
                setData(info?.define_project)
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <section className="define-project">
            <MetaData
                title='Define Your Creative Marketing Project – Instant Quote'
                description='Every creative project starts with an understanding of where you are and where you want to be – What brand, web & marketing services do you need a quote for?'
                canonicalUrl={`${URL_ORIGIN + DEFINE_PROJECT_ROUTE}`}
            />
            <TopSection />
            <Services data={data} setData={setData} />
            <BottomSection
                data={data}
                textareaLabel={textareaLabel}
                numberLabel={numberLabel}
            />
        </section>
    );
});

DefineProject.displayName = 'DefineProject';
