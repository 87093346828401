import React, { memo } from "react";
import { Fade } from "react-reveal";

import "./hero.sass";
import mouse from "assets/images/new/mouse.svg";
import Button from "components/Button";
import { isSafari } from "utils/helpers";

export const Hero = memo((props) => {
    const {
        id,
        title,
        description,
        image,
        image_alt,
        mobile_image,
        video,
        video_alt,
        mobile_video,
        left,
        right,
        withMouse,
        withButton
    } = props

    const distance = "250px";

    const scrollToNext = () =>
        window.scrollTo({ top: window.innerHeight + 15, behavior: "smooth" });

    return (
        <section
            className={`hero ${isSafari ? 'safari' : ''}`}
            id={id}
            style={{
                backgroundImage: window.innerWidth > 1100 ? `url(${!video && image})` : `url(${!mobile_video && mobile_image})`
            }}
        >
            {window.innerWidth > 1100 ? video && (
                <video autoPlay muted loop playsInline preload={'auto'}>
                    <source src={video} type="video/mp4" />
                    <meta itemProp="name" content={video_alt}/>
                </video>
            ) : mobile_video && (
                <video autoPlay muted loop playsInline preload={'auto'}>
                    <source src={mobile_video} type="video/mp4" />
                    <meta itemProp="name" content={video_alt}/>
                </video>
            )}

            { withMouse && (
                <div className="mouse">
                    <img src={mouse} alt="mouse-img" loading={'lazy'} onClick={scrollToNext} />
                </div>
            )}
            {withButton && (
                <div className="mouse">
                    <Button
                        styleType="btn ordinaryBtn white"
                        link={'/define-project'}
                        label="Start Project"
                    />
                </div>
            )}

            <Fade left={left} right={right} distance={distance}>
                <h1 className={withButton ? 'first-title' : ''}>{title}</h1>
            </Fade>

            <Fade left={left} right={right} distance={distance}>
                {description}
            </Fade>
        </section>
    );
});

Hero.displayName = 'Hero';
