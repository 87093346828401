import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { Header, Preloader } from "components";

import {
  AWESOME_ROUTE,
  COOKIE_POLICY_ROUTE,
  DEFINE_PROJECT_ROUTE,
  HOME_ROUTE,
  OUR_TEAM_ROUTE,
  PRIVACY_POLICY_ROUTE,
  WORKSHEET_ROUTE,
  EDUCATION_ROUTE,
  CREDITS_ROUTE,
  NEWS_ROUTE,
  SERVICES_ROUTE,
  ABOUTUS_ROUTE,
  SOLUTIONS_ROUTE,
  BRAND_ROUTE,
  PRODUCTS_ROUTE,
  GENERAL_CONSULTATION_ROUTE,
  BRAND_CONSULTATION_ROUTE,
  WEB_CONSULTATION_ROUTE,
  DIGITAL_CONSULTATION_ROUTE,
  CONSULTATION_WORKSHEET_ROUTE,
  SEARCH_RESULTS_ROUTE,
  CONTACT_ROUTE,
  redirectsArr
} from "constants/routes";
import { DefineProject } from "scenes/DefineProject";

const Home = lazy(() => import("scenes/Home"));
const OurTeam = lazy(() => import("scenes/OurTeam"));
const Worksheet = lazy(() => import("scenes/Worksheet"));
const Awesome = lazy(() => import("scenes/Awesome"));
const Education = lazy(() => import("scenes/Education"));
const News = lazy(() => import("scenes/News"));
const ServicesPage = lazy(() => import("scenes/ServicesPage"));
const AboutUs = lazy(() => import("scenes/AboutUs"));
const Solutions = lazy(() => import("scenes/Solutions"));
const SolutionPage = lazy(() => import("scenes/SolutionPage"));
const OurBrandPage = lazy(() => import("scenes/OurBrand"));
const Products = lazy(() => import("scenes/Products"));
const ProductPage = lazy(() => import("scenes/ProductPage"));
const Consultation = lazy(() => import("scenes/Consultation"));
const ArticlePage = lazy(() => import("scenes/ArticlePage"));
const ConsultationWorksheet = lazy(() => import("scenes/ConsultationWorksheet"));
const SearchResults = lazy(() => import("scenes/SearchResults"));
const Contact = lazy( () => import("scenes/Contact"));

const CookiePolicy = lazy(() => import("scenes/Static/CookiePolicy"));
const PrivacyPolicy = lazy(() => import("scenes/Static/PrivacyPolicy"));
const Credits = lazy(() => import("scenes/Static/Credits"));

export const Routes = () => {
  return (
      <BrowserRouter>
        <Suspense fallback={<Preloader />}>
          <Header />
          <Switch>
            {/* Custom redirects */}
            {redirectsArr?.map((item, key) => (
                <Redirect
                    key={key}
                    push={true}
                    exact={true}
                    from={item.from}
                    to={item.to}
                />
            ))}
            <Route exact={true} path={HOME_ROUTE} component={Home} />
            <Route exact={true} path={OUR_TEAM_ROUTE} component={OurTeam} />
            <Route exact={true} path={WORKSHEET_ROUTE} component={Worksheet} />
            <Route exact={true} path={AWESOME_ROUTE} component={Awesome} />
            <Route exact={true} path={COOKIE_POLICY_ROUTE} component={CookiePolicy} />
            <Route exact={true} path={PRIVACY_POLICY_ROUTE} component={PrivacyPolicy} />
            <Route exact={true} path={CREDITS_ROUTE} component={Credits} />
            <Route exact={true} path={DEFINE_PROJECT_ROUTE} component={DefineProject} />
            <Route exact={true} path={EDUCATION_ROUTE} component={Education} />
            <Route exact={true} path={`${EDUCATION_ROUTE}/:slug`} component={ArticlePage} />
            <Route exact={true} path={NEWS_ROUTE} component={News} />
            <Route exact={true} path={`${NEWS_ROUTE}/:slug`} component={ArticlePage} />
            <Route exact={true} path={SERVICES_ROUTE} component={ServicesPage} />
            <Route exact={true} path={ABOUTUS_ROUTE} component={AboutUs} />
            <Route exact={true} path={SOLUTIONS_ROUTE} component={Solutions} />
            <Route exact={true} path={`${SOLUTIONS_ROUTE}/:slug`} component={SolutionPage} />
            <Route exact={true} path={BRAND_ROUTE} component={OurBrandPage} />
            <Route exact={true} path={PRODUCTS_ROUTE} component={Products} />
            <Route exact={true} path={`${PRODUCTS_ROUTE}/:slug`} component={ProductPage} />
            <Route exact={true} path={GENERAL_CONSULTATION_ROUTE} component={Consultation} />
            <Route exact={true} path={BRAND_CONSULTATION_ROUTE} component={Consultation} />
            <Route exact={true} path={WEB_CONSULTATION_ROUTE} component={Consultation} />
            <Route exact={true} path={DIGITAL_CONSULTATION_ROUTE} component={Consultation} />
            <Route exact={true} path={CONSULTATION_WORKSHEET_ROUTE} component={ConsultationWorksheet} />
            <Route exact={true} path={SEARCH_RESULTS_ROUTE} component={SearchResults} />
            <Route exact={true} path={CONTACT_ROUTE} component={Contact} />

            {/*<Redirect from={'/website-design'} to={AWESOME_ROUTE} />*/}
            {/*<Redirect from={'/google-ads'} to={COOKIE_POLICY_ROUTE} />*/}
            {/*<Redirect from={'/brand-design'} to={SERVICES_ROUTE} />*/}
            {/*<Redirect from={'/website-brochure-design'} to={PRODUCTS_ROUTE} />*/}

            {/*<Redirect from={DEFINE_PROJECT_ROUTE} to={GENERAL_CONSULTATION_ROUTE} />*/}
            <Redirect to={HOME_ROUTE} />
          </Switch>
        </Suspense>
      </BrowserRouter>
  );
};
