import React, {createRef, memo, useEffect, useState} from "react";
import './ourValues.sass';

import { HeroTriangle } from "components";

export const OurValues = memo(() => {
  const ourValuesContainer = createRef();
  const [state, setState] = useState("top");

  const scrollHandler = () => {
    // minimum width with correct layout
    if (window.innerWidth >= 350) {
      const top = ourValuesContainer?.current?.getBoundingClientRect().top;
      const height = document.querySelector('.hero-triangle').offsetHeight;
      const figures = document.querySelector('.our-values-figures');

      let _top = 250;
      let _bottom = 350;

      if (window.innerWidth < 1530 && window.innerWidth >= 992) {
        _bottom = 650
        _top = -200
      }

      if (window.innerWidth < 992) {
        _top = -50
        _bottom = 250
      }


      if (top < _top && top > (height * -3 - _bottom)) {
        if (!figures.classList.contains('in')) {
          figures.classList.add('in')
        }

        if (top > height * -1) {
          if (state !== "top") {
            setState('top')
          }
        } else if (top <= height * -1 && top > height * -2) {
          if (state !== 'top right') {
            setState('top right')
          }
        } else if (top <= height * -2 && top > height * -3) {
          if (state !== 'top right left') {
            setState('top right left')
          }
        } else {
          if (state !== 'full') {
            setState('full');
          }
        }
      } else {
        if (figures.classList.contains('in')) {
          figures.classList.remove('in')
          setState("none");
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return () => document.removeEventListener('scroll', scrollHandler);
  });


  return (
      <section className="our-values" id="winning-formula" style={{ marginTop: '100px' }} ref={ourValuesContainer}>
        <div className="wrapper" >

          <HeroTriangle
              id="our-values"
              state={state}
              title={"Wonderful…"}
              figures={true}
              subTitle={
                <h2>
                  We can take you from ordinary to awesome,
                  engaging with and exciting your <b>audience</b> along the way.
                  It’s a way of working that feels good and does great.
                </h2>
              }
              visible={window.innerWidth < 1530 || state === 'top'}
          />
          <HeroTriangle
              state="right"
              title={"Inspired…"}
              figures={window.innerWidth < 1530}
              subTitle={
                <h2>
                  Our focus is on achieving your strategic vision and <b>business ambitions</b>,
                  wherever you are and wherever you want to be, now and in the future.
                </h2>
              }
              visible={state === 'top right' || state === 'top'}
          />
          <HeroTriangle
              state="left"
              title={"Necessary…"}
              figures={window.innerWidth < 1530}
              subTitle={
                <h2>
                  Essential strategies, structured for success, designed to deliver results from experience and <b>tailored analysis</b>.
                  All underpinned by a small yet perfectly balanced team with 20+ years’ experience.
                </h2>
              }
              visible={state === 'top right left' || state === 'top right'}
          />
          <HeroTriangle
              state="full"
              figures={window.innerWidth < 1530}
              title={'It’s WIN/WIN'}
              subTitle={
                <h2>
                  Our <b>WIN formula</b> adds up to more, delivering business potential through a partnership designed to maximise your brand and tell your story.
                  <br />
                  <br /><b>W</b>onderful.  <b>I</b>nspired.  <b>N</b>ecessary
                </h2>
              }
              visible={state === 'full' || state === 'top right left'}
          />
        </div>
      </section>
  )
})
