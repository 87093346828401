import React, {memo} from 'react';

import './pageTitle.scss';
import {removeInlineStyles} from "utils/helpers";

export const PageTitle = memo(({ title, subtitle, link, goBack, caption}) => {
    const createMarkup = (content) => {
        return { __html: removeInlineStyles(content) };
    };

    return (
        <section className='page-title'>
            <div className={caption ? 'content columns' : 'content'}>
                <div className="text">
                    {title && <p className='title'>{title}</p>}
                    {link && <p className='back__link'
                                onClick={ goBack }>{link}</p>}
                    <h1 className="subtitle">{subtitle}</h1>
                </div>
                {caption &&
                    <div className="caption">
                        <div className="title">
                            {caption?.title}
                            <a href={`tel:${caption?.titleTel.replace(/[^0-9.]/g, '')}`}>{caption?.titleTel}</a>
                        </div>
                        <div className="subtitle">
                            {/*<div className="column telephone">*/}
                            {/*    Telephone: <a href={`tel:${caption?.telephone}`}>{caption?.telephone}</a>*/}
                            {/*</div>*/}
                            <div className="column email">
                                Email: <a href={`mailto:${caption?.email}`}>{caption?.email}</a>
                            </div>
                        </div>
                        <div className="bottom" dangerouslySetInnerHTML={createMarkup(caption?.description)}></div>
                    </div>
                }
            </div>
        </section>
    )
})

PageTitle.displayName = 'PageTitle';
