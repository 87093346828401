import React, {createRef, memo, useEffect} from "react";

import "./randomMovements.sass";

import { ReactComponent as Triangle } from "assets/images/new/Brand assets/Values Triangle.svg";
import { ReactComponent as Cross } from "assets/images/new/Brand assets/Client Cross.svg";
import { ReactComponent as Hexagon } from "assets/images/new/Brand assets/Process Hexagon.svg";

export const RandomMovements = memo(() => {
  // const crossWhite = createRef();
  const triangle = createRef();
  const cross = createRef();
  const hexagon = createRef();

  const spaceW = window.screen.width * 0.9;
  const spaceH = window.screen.height * 0.9;
  const minSize = 40;
  const maxSize = 600;

  const getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const moveIt = (element) => {
    if (element && element.current) {
      element.current.style.top = Math.round(Math.random() * spaceH) + "px";
      element.current.style.left = Math.round(Math.random() * spaceW) + "px";
      element.current.style.width = getRandomIntInclusive(minSize, maxSize) + 'px';
      element.current.style.transform = `rotate(${Math.random() * 360 * (Math.random() < 0.5 ? -1 : 1)}deg)`;
    }
  };
  //time
  // =
  const repeatedMove = (elem, time = Math.round(Math.random() * 10) * 1000 + 10000) => {
    setInterval(() => {
      moveIt(elem, time);
    }, time);
  };


  useEffect(() => {

    // moveIt(crossWhite);
    moveIt(triangle);
    moveIt(cross);
    moveIt(hexagon);

    repeatedMove(triangle, 40000);
    // repeatedMove(crossWhite, 35000);
    repeatedMove(cross, 40000);
    repeatedMove(hexagon, 40000);
  });

  return (
      <section className='random-movements'>
        {/* <Cross ref={crossWhite} className="cross-white" /> */}
        <Triangle ref={triangle} className='triangle' />
        <Cross ref={cross} className='cross' />
        <Hexagon ref={hexagon} className='hexagon' />
      </section>
  );
});

RandomMovements.displayName = 'RandomMovements';
