import React, {memo} from 'react';
import { MediaSection } from 'components/MediaSection';

import './ArticleSection.scss';
import {removeInlineStyles} from "utils/helpers";
import {useInView} from "react-intersection-observer";

export const ArticleSection = memo(({ description, first_image, first_image_alt, first_image_shadow, second_image, second_image_alt, second_image_shadow, video, video_link, second_description }) => {
    const createMarkup = (description) => {
        return { __html: removeInlineStyles(description) };
    };

    const { ref: inViewRef, inView } = useInView({
        triggerOnce: true,
        rootMargin: '200px'
    });

    if (description) {
        return (
            <div className="article-section" ref={inViewRef}>
                <div className="content" style={{ display: !inView ? 'none' : null }}>
                    <div className="section__description" dangerouslySetInnerHTML={createMarkup(description)}/>
                    <div className="section__image">
                        {!second_image ?
                            (first_image && <MediaSection image={first_image} image_alt={first_image_alt} block_shadow={first_image_shadow} video={video} youtube_link={video_link} />)
                            :
                            (
                                <>
                                    <div className="left_image">
                                        <img src={first_image} loading={'lazy'} alt={first_image_alt} style={{ filter: first_image_shadow ? "" : "unset" }} />
                                    </div>
                                    <div className="right_image">
                                        <img src={second_image} loading={'lazy'} alt={second_image_alt} style={{ filter: second_image_shadow ? "" : "unset" }} />
                                    </div>
                                </>
                            )}
                    </div>
                    {second_description && <div className="section__description" dangerouslySetInnerHTML={createMarkup(second_description)}/>}
                </div>
            </div>
        )
    }

    return null;
})

ArticleSection.displayName = 'ArticleSection';
