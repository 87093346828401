import React, {memo, useState} from 'react';

import './filterTabs.scss';

export const FilterTabs = memo(({ filter, onFilterChange, options, buttons }) => {
    const [selectedOption, setSelectedOption] = useState('View All');

    const toggleOptionContainer = () => {
        const optionsContainer = document.querySelector(".options-container");
        optionsContainer.classList.toggle("active");
    }

    const filterButtons = buttons.map(({ name, label }) => {
        const isActive = filter === name;
        const clazz = isActive ? 'active' : '';
        return (
            <button type='button'
                    className={`tab__btn ${clazz}`}
                    key={name}
                    onClick={() => {
                        onFilterChange(name);
                    }}>
                {label}
            </button>
        )
    });

    const filterSelect = options.map(({ value, label }) => {
        return (
            <div className="option" key={value}>
                <input type="radio" className="radio" id="all" name="category" value={value} />
                <label htmlFor="all"
                       onClick={() => {
                           onFilterChange(value);
                           setSelectedOption(label);
                           toggleOptionContainer();
                       }}>
                    {label}
                </label>
            </div>
        )
    });

    return (
        <section className="filter-tabs">
            <div className="tabs__content">
                <div className="categories__tabs">
                    {filterButtons}
                </div>
                <div className="categoties__select">
                    <div className="select-box">
                        <div className="options-container">
                            {filterSelect}
                        </div>
                        <div className="selected"
                             onClick={toggleOptionContainer}>
                            {selectedOption}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
})
