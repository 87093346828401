export const HOME_ROUTE = "/";

export const OUR_TEAM_ROUTE = "/our-team";
export const DEFINE_PROJECT_ROUTE = "/define-project";
export const WORKSHEET_ROUTE = "/worksheet";
export const AWESOME_ROUTE = "/awesome";
export const EDUCATION_ROUTE = "/insights";
export const EDUCATION_OLD_ROUTE = "/education";
export const NEWS_ROUTE = "/blog";
export const SERVICES_ROUTE = "/services";
export const ABOUTUS_ROUTE = "/about-us";
export const SOLUTIONS_ROUTE_OLD = "/solutions";
export const SOLUTIONS_ROUTE = "/case-studies";
export const BRAND_ROUTE = "/our-brand";
export const PRODUCTS_ROUTE_OLD = "/products";
export const PRODUCTS_ROUTE = "/our-solutions";
export const CONSULTATION_WORKSHEET_ROUTE = "/consultation-worksheet";
export const SEARCH_RESULTS_ROUTE = "/search-results";
export const CONTACT_ROUTE = '/contact';

export const GENERAL_CONSULTATION_ROUTE = "/business-consultation";
export const BRAND_CONSULTATION_ROUTE = "/brand-consultation";
export const WEB_CONSULTATION_ROUTE = "/web-consultation";
export const DIGITAL_CONSULTATION_ROUTE = "/marketing-consultation";

export const COOKIE_POLICY_ROUTE = "/cookie-policy";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const CREDITS_ROUTE = "/credits";

export const redirectsArr = [
    {
        from: EDUCATION_OLD_ROUTE,
        to: EDUCATION_ROUTE
    },
    {
        from: `${EDUCATION_OLD_ROUTE}/:slug`,
        to: `${EDUCATION_ROUTE}/:slug`
    },
    {
        from: SOLUTIONS_ROUTE_OLD,
        to: SOLUTIONS_ROUTE
    },
    {
        from: `${SOLUTIONS_ROUTE_OLD}/:slug`,
        to: `${SOLUTIONS_ROUTE}/:slug`
    },
    {
        from: PRODUCTS_ROUTE_OLD,
        to: PRODUCTS_ROUTE
    },
    {
        from: `${PRODUCTS_ROUTE_OLD}/:slug`,
        to: `${PRODUCTS_ROUTE}/:slug`
    },
    {
        from: '/case-studies/chartered-surveyors-full-rebrand-marketing-and-website-build',
        to: '/case-studies/arnold-baldwin-rebrand'
    },
    {
        from: '/case-studies/building-next-generation-website-design',
        to: '/case-studies/arnold-baldwin-web-design'
    },
    {
        from: '/case-studies/brand-creation-graphic-design-website-build-and-social-marketing',
        to: '/case-studies/maddison-consultants'
    },
    {
        from: '/case-studies/complete-brand-development',
        to: '/case-studies/cellxica'
    },
    {
        from: '/case-studies/brand-development-website-build-social-launch',
        to: '/case-studies/holmes-search'
    },
    {
        from: '/case-studies/brand-development-digital-marketing',
        to: '/case-studies/cmbfoods'
    },
    {
        from: '/case-studies/brand-development-marketing-website-build',
        to: '/blog/the-spot'
    },
    {
        from: '/insights/juice-up-your-website-squeeze-more-out-of-seo',
        to: '/insights/link-juice'
    },
    {
        from: '/insights/business-branding-brand-design',
        to: '/insights/branding-guide'
    },
    {
        from: '/insights/memorable-distinctive-brand-design',
        to: '/insights/brand-design'
    },
    {
        from: '/insights/how-to-get-your-website-design-right',
        to: '/insights/wordpress-custom-website'
    },
    {
        from: '/insights/company-business-rebranding',
        to: '/insights/rebranding'
    },
    {
        from: '/insights/branding-five-reasons-why-to-invest',
        to: '/insights/branding-five-reasons'
    },
    {
        from: '/insights/brand-marketing-touchpoints-collateral-why-you-need-them',
        to: '/insights/brand-touchpoints'
    },
    {
        from: '/insights/how-to-design-a-great-brochure-the-ultimate-guide',
        to: '/insights/brochure-design-guide'
    },
    {
        from: '/insights/best-custom-website-design',
        to: '/insights/website-design'
    },
    {
        from: '/blog/bespoke-brochure-design-stainless-steel-fasteners',
        to: '/blog/pts'
    },
    {
        from: '/blog/website-build-booking-functionality-for-camping-touring-park',
        to: '/blog/longacres-camping'
    },
    {
        from: '/blog/thacker-mews-development-brand-identity-brochure-website-design',
        to: '/blog/thacker-mews'
    },
    {
        from: '/blog/hot-property-total-success',
        to: '/blog/total-homes'
    },
    {
        from: '/blog/sales-brochure-design-controllis',
        to: '/blog/controllis'
    },
    {
        from: '/blog/wealthbuilders-website-design',
        to: '/blog/wealthbuilders'
    },
    {
        from: '/blog/custom-website-design-chartered-surveyors',
        to: '/blog/chartered-surveyors'
    },
    {
        from: '/blog/rejoined-the-bni-global-network',
        to: '/blog/bni'
    },
    {
        from: '/blog/voted-on-the-world-brand-design-society-community',
        to: '/blog/voted'
    },
    {
        from: '/blog/fire-security-brochure-folder-design',
        to: '/blog/fire-security'
    },
    {
        from: '/blog/wavemobile-the-network-for-mobile-not-spots',
        to: '/blog/wavemobile'
    },
    {
        from: '/blog/full-service-brand-web-creative-design-agency',
        to: '/blog/creative-agency'
    },
    {
        from: '/blog/brand-identity-website-design-package-now-launched',
        to: '/blog/brand-package'
    },
    {
        from: '/blog/corporate-book-brochure-design',
        to: '/blog/inovo-group'
    },
    {
        from: '/blog/external-building-brand-logo-design',
        to: '/blog/building-roofing'
    },
    {
        from: '/blog/brand-logo-designs-the-collection',
        to: '/blog/brand-logo-designs'
    },
    {
        from: '/blog/branding-your-most-valuable-investment-your-most-powerful-business-tool',
        to: '/blog/branding-investment'
    },
    {
        from: '/blog/sumus-ethical-sports-clothing-brand-design',
        to: '/blog/sumus'
    },
    {
        from: '/blog/metalcraft-shopify-website-design',
        to: '/blog/metalcraft'
    },
    {
        from: '/blog/electrician-brand-identity-design',
        to: '/blog/cerberus'
    },
    {
        from: '/blog/any-web-solution-any-app-any-industry-any-purpose',
        to: '/blog/web-development'
    },
    {
        from: '/blog/branding-fitness-trainer-wellness-centre',
        to: '/blog/the-spot'
    },
    {
        from: '/blog/brand-refresh-web-design-eco-cleaning-company',
        to: '/blog/new-concept'
    },
    {
        from: '/blog/website-design-electrical-engineers',
        to: '/blog/electrical-engineers'
    },
    {
        from: '/digital-consultation',
        to: '/marketing-consultation'
    },
    {
        from: '/general-consultation',
        to: '/business-consultation'
    },
    {
        from: '/our-solutions/identity-web-design',
        to: '/our-solutions/brand-identity-design'
    },
    {
        from: '/our-solutions/startups-rebrands',
        to: '/our-solutions/branding-rebranding'
    },
    {
        from: '/our-solutions/upstart-launchyourbusiness',
        to: '/our-solutions/branding-rebranding'
    },
    {
        from: '/our-solutions/growth-minded',
        to: '/our-solutions/creative-marketing-support'
    },
    {
        from: '/our-solutions/skyrocket-increaseyourrevenue',
        to: '/our-solutions/creative-marketing-support'
    },
    {
        from: '/our-solutions/growth-minded-business',
        to: '/our-solutions/creative-marketing-support'
    },
]
