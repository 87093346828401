import React from "react";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";

import "./heroTriangle.sass";
import { OUR_TEAM_ROUTE } from "constants/routes";

import triangle from "assets/images/new/Values/Triangle-blue-shadow.svg";
import hexagon from "assets/images/new/Our process/hexagon-white-outlined.svg";

export const HeroTriangle = ({ title, subTitle, state = "none", figures, visible = true }) => {
  return (
    <section className="hero-triangle">
      <div className="content">
        {visible &&
          <Fade duration={500}>
            <div className="info">
              <div>
                <h1 className="title">{title}</h1>
                {subTitle}
              </div>
            </div>
          </Fade>
        }
        {figures &&
          <div className="figures our-values-figures">
            <img src={triangle} alt="blue triangle" className="triangle" />
            {(state.includes("top") || state === "full") && (
              <Zoom delay={500}>
                <img src={hexagon} alt="hexagon" className="hexagon-top" />
              </Zoom>
            )}
            {(state.includes("right") || state === "full") && (
              <Zoom delay={state.includes("right") ? 500 : 1000}>
                <img src={hexagon} alt="hexagon" className="hexagon-right" />
              </Zoom>
            )}
            {(state.includes("left") || state === "full") && (
              <Zoom delay={state.includes("left") ? 500 : 1500}>
                <img src={hexagon} alt="hexagon" className="hexagon-left" />
              </Zoom>
            )}
          </div>
        }

        {(window.innerWidth >= 1500 ||
          (state === "full" && window.innerWidth < 1500)) && (visible &&
            <Fade>
              <div className="meet-team">
                <Link to={OUR_TEAM_ROUTE}>
                  Meet our Team
              </Link>
              </div>
            </Fade>
          )}
      </div>
    </section>
  );
};
