import { useLayoutEffect, useRef, useState} from "react";
import { useHistory, useLocation } from 'react-router-dom';


let scrollCount = 0

const scrollToElement = (id) => {
  if (!id) return

  const element = document.getElementById(id);

  const rect = element.getBoundingClientRect();
  const isVisibleRect = rect.top >= 0 && rect.bottom <= window.innerHeight;
  const isMobile = window.innerWidth <= 992

  if (!isVisibleRect) {
    element.scrollIntoView({ block: 'start', behavior: 'smooth' });

    if (!isMobile) {
      scrollToElement()
      return false
    }
  } else  {
    return true
  }
}

export const useScrollToElement = () => {
  const { pathname, hash } = useLocation();
  const history = useHistory()
  const anchorElem =   useRef()
  const intervalRef =   useRef()
  const [isVisible, setIsVisible] = useState(false)

  useLayoutEffect(() => {
    if (isVisible) {
      clearInterval(intervalRef.current)
    }

    const id = hash.replace("#", "");

    const element = document.getElementById(id);

    if (!element && hash === '') {
      window.scrollTo({
        top: 0
      })
      return
    }

    anchorElem.current = element

    intervalRef.current = setInterval(() => {
      scrollCount++

      const isScrolled = scrollToElement(id)

      if (isScrolled || scrollCount > 3) {
        // history.replace(pathname, null, '');
        setIsVisible(true)
        clearInterval(intervalRef.current)
      }
      }, 650)



    return () => {
      clearInterval(intervalRef.current)
    }

  }, [pathname, hash, history, isVisible]); // do this on route change
};
