import React, { useEffect, useState } from "react";

import "./bottomSection.sass";
import arrowDown from "assets/images/new/Define Your Project/down-arrow.svg";
import { ReactComponent as Price } from "assets/images/new/Define Your Project/price-hexagon.svg";

import ContactForm from "components/ContactUs/ContactForm";
import { Footer } from 'components';
import { BASE_URL } from "constants/api";

export const BottomSection = ({ data, textareaLabel, numberLabel }) => {
  const [totalSum, setTotalSum] = useState(0);
  const [activeBlocks, setActiveBlocks] = useState(null)

  const scrollToStart = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const calculateTotalSum = (array) =>
    array?.reduce((acc, curr) => {
      return acc + curr.blocks.reduce((acc, cur) => {
        return cur.isActive ? acc + Number(cur.price) : acc;
      }, 0)
    }, 0)

  const getBlocksId = (array) => array?.map(item => item.blocks.map(block => block.isActive ? block.id : null));

  const getActiveId = (array) =>
    array?.reduce((total, amount) => {
      return total.concat(amount).filter(id => id !== null);
  }, []);


  useEffect(() => {
    if (data.length > 0) {
      setTotalSum(calculateTotalSum(data));
      setActiveBlocks(getActiveId(getBlocksId(data)));
    }
  }, [data]);

  return (
    <div className="bottom-section">
      <button type="button" className="not-done" onClick={scrollToStart}>
        I’m not done! Let me go back <img src={arrowDown} alt="arrow-down" />
      </button>

      {/*<div className="total-price">*/}
      {/*  <h2 className="description">Your <br /> estimated <br /> quote</h2>*/}
      {/*  <div className="price">*/}
      {/*    <div className="text">*/}
      {/*      <h2 className="title">*/}
      {/*        Investment <br /> from only*/}
      {/*      </h2>*/}
      {/*      <div className="amount">*/}
      {/*        <h2><span>£{totalSum}</span></h2>*/}
      {/*        <span>per month</span>*/}
      {/*      </div>*/}
      {/*      <div className="tip">*Subject to consultation.<br /> Minimum term applies.*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <Price />*/}
      {/*  </div>*/}
      {/*</div>*/}

      <h4 className="before-form">
        Let’s Shape Brand Success…
      </h4>

      <div className="form-wrapper">
        <ContactForm
          formType={'define'}
          twoColumn={true}
          buttonText="Submit"
          subject={'Define your project'}
          total={totalSum}
          blocks={activeBlocks}
          textareaLabel={textareaLabel}
          numberLabel={numberLabel}
          url={`${BASE_URL}/enquiry/form/define-project`}/>
      </div>

      <Footer />
    </div>
  );
};
