import React, { Component } from 'react'

import { ReactComponent as Facebook } from 'assets/images/new/Social icons/facebook-white.svg'
import { ReactComponent as Twitter } from 'assets/images/new/Social icons/twitter-white.svg'
import { ReactComponent as LinkedIn } from 'assets/images/new/Social icons/linkedin-white.svg'
import { ReactComponent as Instagram } from 'assets/images/new/Social icons/instagram.svg'

export default class SocialLinks extends Component {
  render() {
    const { color, dark, connected = false } = this.props;

    return (
      <div className="social-icons">
        {connected && <span style={{ color: dark ? color : '' }}>Stay connected</span>}
        <ul style={{display: "flex"}}>
          <li>
            <a href="https://www.facebook.com/HeightonAgency/" target="_blank" rel="noopener noreferrer">
              <Facebook className={dark ? "dark" : ''} />
            </a>
          </li>

          <li>
            <a href="https://twitter.com/heightonagency/" target="_blank" rel="noopener noreferrer">
              <Twitter className={dark ? "dark" : ''} />
            </a>
          </li>

          <li>
            <a href="https://www.linkedin.com/company/heightonagency/" target="_blank" rel="noopener noreferrer">
              <LinkedIn className={dark ? "dark" : ''} />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/heightonagency/" target="_blank" rel="noopener noreferrer">
              <Instagram className={dark ? "dark" : ''} />
            </a>
          </li>
        </ul>
      </div>
    )
  }
}