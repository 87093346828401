import React from 'react';
import { Zoom, Fade } from 'react-reveal';

import './heroHexagon.sass';

import hexagonPurple from 'assets/images/new/Our process/hexagon-purple-shadow.svg'
import triangle from 'assets/images/new/Our process/triangle-white-outlined.svg'
import diamond from 'assets/images/new/Our process/diamond-white-outlined.svg'
import cross from 'assets/images/new/Our process/x-white-outlined.svg'
import circle from 'assets/images/new/Our process/circle-white-outlined.svg'
import hexagon from 'assets/images/new/Our process/hexagon-white-outlined.svg'
import square from 'assets/images/new/Our process/square-white-outlined.svg'

export const HeroHexagon = ({ title, subtitle, figures, state = 'none', visible = false }) => {
    return (
        <section className='hero-hexagon'>
            <div className="content">
                {visible &&
                    <Fade duration={500}>
                        <div className="info">
                            {title}
                            {subtitle}
                        </div>
                    </Fade>
                }
                {figures &&
                    <div className="figures process-figures">
                        <img src={hexagonPurple} alt="hexagon" id="hexagon" loading={'lazy'} />
                        {(state.includes("top") || state === "full") && (
                            <React.Fragment>
                                <Zoom delay={200}>
                                    <img src={triangle} alt="triangle" className="triangle" loading={'lazy'} />
                                </Zoom>
                                <Zoom delay={400}>
                                    <img src={cross} alt="cross" className="cross" loading={'lazy'}/>
                                </Zoom>
                            </React.Fragment>
                        )}
                        {(state.includes("center") || state === "full") && (
                            <React.Fragment>
                                <Zoom delay={state.includes("center") ? 200 : 600}>
                                    <img src={circle} alt="circle" className="circle" loading={'lazy'} />
                                </Zoom>
                                <Zoom delay={state.includes("center") ? 400 : 800}>
                                    <img src={square} alt="square" className="square" loading={'lazy'} />
                                </Zoom>
                            </React.Fragment>
                        )}
                        {(state === "full") && (
                            <React.Fragment>
                                <Zoom delay={200}>
                                    <img src={hexagon} alt="hexagon" className="hexagon" loading={'lazy'} />
                                </Zoom>
                                <Zoom delay={400}>
                                    <img src={diamond} alt="diamond" className="diamond" loading={'lazy'} />
                                </Zoom>
                            </React.Fragment>
                        )}
                    </div>
                }

            </div>
        </section>
    )
}
